$(document).ready(function() {
  // Wallet filter balance history
  var currentYear = moment().year();

  var currentYearStart = moment({
    years: currentYear,
    months: '0',
    date: '1'
  });

  var currentYearEnd = moment({
    years: currentYear,
    months: '11',
    date: '31'
  });

  var start = moment().subtract(29, 'days');

  // BEGINNING OF MONTH START FILTER
  if ($('#reservation').length > 0 && $('#reservation').data('start') == "beginning-of-month") {
    start = moment().clone().startOf('month');
  }

  var end = moment();

  function cb(start, end) {
    $('#reservation').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
  }

  var dateRange = {};
  dateRange["Today"] = [moment(), moment()];
  dateRange["Yesterday"] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
  dateRange["Last 7 Days"] = [moment().subtract(6, 'days'), moment()];
  dateRange["Last 30 Days"] = [moment().subtract(29, 'days'), moment()];
  dateRange["This Month"] = [moment().startOf('month'), moment().endOf('month')];
  dateRange["Last Month"] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

  $('#reservation').daterangepicker({
    startDate: start,
    endDate: end,
    ranges: dateRange
  }, cb);

  cb(start, end);

  $('#reservation').on('apply.daterangepicker', function(ev, picker) {
    var buttonId = $(ev.currentTarget).data('submit-button');
    $(buttonId).click()
  });
})