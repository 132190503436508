$(document).ready(function() {
  $.each($(".datatables"), function(id, elm){
    var searching = $(elm).data('searching-bar');
    if (searching == undefined) { searching = true };

    $(elm).DataTable({
      "ordering": false,
      "searching": searching
    });
  });

  $.each($(".custom-datatables"), function(id, elm){
    var searching = $(elm).data('searching-bar');
    var paging = $(elm).data('pagination-bar');

    if (searching == undefined) { searching = true };
    if (paging == undefined) { paging = true };

    var dT = $(elm).DataTable({
      "ordering": false,
      "searching": searching,
      "paging": paging
    });

    var checkboxes = $(elm).find('.code-datatables-checkbox');
    $.each(checkboxes, function(id, elm){
      $(elm).on('change', function(event){
        dT.search('').draw()
      })
    })

    $(elm).parents('form').submit(function(){
      dT.search('').draw();
      return true
    })
  })

  $('.datepicker, #datepicker, .input-group.date').bootstrapDP({
      autoclose: true,
      format: "dd/mm/yyyy",
      todayHighlight: true,
      orientation: "bottom"
  });

  $('.yearpicker').bootstrapDP({
    format: "yyyy",
    weekStart: 1,
    orientation: "bottom",
    language: "{{ app.request.locale }}",
    keyboardNavigation: false,
    viewMode: "years",
    minViewMode: "years"
  });

  $('.month-year-datepicker').bootstrapDP({
    format: "mm/yyyy",
    startView: "months",
    minViewMode: "months",
    endDate: "today"
  });

  // // Date range picker with placeholder
  $('.daterange').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: 'DD/MM/YYYY'
    }
  });

  $('.daterange').each(function(i, obj) {
    var value_daterange = $(this).val().split(' - ')
    if (value_daterange.length >= 2) {
      $(this).data('daterangepicker').setStartDate(value_daterange[0]);
      $(this).data('daterangepicker').setEndDate(value_daterange[1]);
    }
  });

  $('.daterange').on('apply.daterangepicker', function(ev, picker) {
    // $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  });

  $('.daterange').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $('.daterange').attr("placeholder","Select Date");

  $('.datetimepicker').datetimepicker({
    format: "DD/MM/YYYY hh:mm A",
    icons: {
      time:'zmdi zmdi-time',
      date:'zmdi zmdi-calendar-alt',
      up:'zmdi zmdi-chevron-up',
      down:'zmdi zmdi-chevron-down',
      previous:'zmdi zmdi-chevron-left',
      next:'zmdi zmdi-chevron-right',
      today:'zmdi zmdi-calendar-check',
      clear:'zmdi zmdi-tag-close',
      close:'zmdi zmdi-close'
    }
  });

  $('.time-picker').datetimepicker({
    format: 'LT'
  });

  $('.summernote').summernote({
    height: 350,
    minHeight: null,
    maxHeight: null,
    focus: false
  });

    // SUMMERNOTE REQUIRED VALIDATION
    if($('.summernote').data('required')){
      var summernoteForm = $('.summernote').closest("form");
      summernoteForm.on('submit', function(e) {
        if($('.summernote').summernote('isEmpty')) {
          if ($('#error-message').text() == '') {
            $('.summernote').after('<small class="text-danger" id="error-message">This field is required</small>');
          }
          // cancel submit
          return false;
        }
      })
    }

  $('.user-sidenav-mobile-toggler').click(function(){
    $(this).toggleClass('show');
    $('.user-sidebar').toggleClass('show');
  })

  $.each($(".select2"), function(id, elm){
    var data_tag   = $(elm).attr('data-allow-new-value');
    var tagEnabled = (data_tag != undefined);

    $(elm).select2({
      tags: tagEnabled,
      width: 'resolve'
    });
  })

  $(".wrapper-scrollbar-one").scroll(function(){
    $(".wrapper-scrollbar-two").scrollLeft($(".wrapper-scrollbar-one").scrollLeft());
  });
  $(".wrapper-scrollbar-two").scroll(function(){
    $(".wrapper-scrollbar-one").scrollLeft($(".wrapper-scrollbar-two").scrollLeft());
  });

  $('.scrollbar-item-one').css('width', $('.scrollbar-item-two').width() + 'px')

  $('#btn-generate-invitation-link').click(function() {
    var active_system_upline_abo_number = $('#active-system-upline').val()
    var guest_invitation                = $('#guest-invitation').val()

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'abo_number' : active_system_upline_abo_number, 'with_current_account' : true,
              'generate_invitation' : true, 'only_platinum_above' : false, 'guest_invitation' : guest_invitation },
      url: "/api/services/get_platinum_active_system_upline",
      success: function(response){
        var errMsg = $('#error-message-active-system-upline-abo').length

        if (!(response.active_system_upline.id == undefined) && errMsg <= 0) {
          $('#clipboard-link-sign-up-link').val(response['url_invitation'])
          $('#btn-copy-link').attr("data-link", response['url_invitation'])
          $('#btn-copy-link').removeClass('d-none')
          $('#clipboard-link-sign-up-link').parent().removeClass('d-none')
        } else {
          $('#btn-copy-link').addClass('d-none')
          $('#clipboard-link-sign-up-link').val()
          $('#clipboard-link-sign-up-link').parent().addClass('d-none')
          alert('Active System Upline ABO Number Not Found!')
        }
      }
    });
  })

  $('#btn-generate-invitation-guest-link').click(function() {
    var active_system_upline_abo_number = $('#active-system-upline').val()

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'abo_number' : active_system_upline_abo_number, 'with_current_account' : true,
              'generate_invitation' : true, 'only_platinum_above' : false, 'guest_invitation' : true },
      url: "/api/services/get_platinum_active_system_upline",
      success: function(response){
        if (!(response.active_system_upline.id == undefined)) {
          $('#clipboard-link-sign-up-link').val(response['url_invitation'])
          $('#btn-copy-link').attr("data-link", response['url_invitation'])
          $('#clipboard-link-sign-up-link').parent().removeClass('d-none')
          $('#btn-copy-link').click()
        } else {
          $('#clipboard-link-sign-up-link').val()
          $('#clipboard-link-sign-up-link').parent().addClass('d-none')
          alert('Active System Upline ABO Number Not Found!')
        }
      }
    });
  })
});
