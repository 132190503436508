$(document).ready(function() {
  const CreateQrCodePaseAttendance = (unique_id) => CreateQrCode(unique_id);
  window.CreateQrCodePaseAttendance = CreateQrCodePaseAttendance;

  function CreateQrCode(unique_id) {
    var qrcode = new QRCode(document.getElementById("qrcode"), {
    text: `host=${document.location.host}&unique_id=${unique_id}`,
    width: 300,
    height: 300,
    colorDark : "#000000",
    colorLight : "#ffffff",
    correctLevel : QRCode.CorrectLevel.H
    });
  }
})
