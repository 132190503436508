$(document).ready(function() {
  var abo_number = $("#abo-number").val();
  var abo_number_user = $('.account_abo_number').val()
  $("#abo-number").keyup(function(){
    aboNumberValidationAboNumber($(this).val(), abo_number_user);
  });

  $("#abo-number").change(function(){
    aboNumberValidationAboNumber($(this).val(), abo_number_user);
  });
})

function aboNumberValidationAboNumber(get_abo_number, abo_number_user) {
  if (get_abo_number == abo_number_user) {
    $("#abo-number-error").text("");
    $('#click-submit').prop('disabled', false)
  } else {
    aboNumberValidation();
  }
}

function aboNumberValidation(){
  var abo_number = $('#abo-number').val();
  var id         = $('#account-obj-id').val();
  if (id == undefined) {
    id = null;
  }

  $.ajax({
    type: "GET",
    dataType:"json",
    data: {'abo_number' : abo_number, 'id': id},
    url: "/api/services/abo_number_validation",
    error: function (xhr, ajaxOptions, thrownError) {
      abuNumberError();
    },
    success: function(response){
      if (abo_number == "") {
        $("#abo-number-error").text("");
        $('#click-submit').prop('disabled', false)
      } else if (response['result'] == false || response['valid_length'] == true) {
        abuNumberError(response);
        $('#click-submit').prop('disabled', true)
      } else {
        $("#abo-number-error").text("");
        $('#click-submit').prop('disabled', false)
        return true;
      }
    }
  });
}

function abuNumberError(response){
  var abo_number = $('#abo-number').val();
  $("#abo-number-error").attr('style', '');
  $("#abo-number-error").text("");
  if ($("#abo-number-error").text().length < 1) {
    if (response['result'] == false) {
      $("#abo-number-error").append("ABO Number already exist.");
    } else if (response['valid_length'] == true) {
      $("#abo-number-error").append("ABO Number length 7-10");
    }
  }
  return false;
}