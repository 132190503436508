$(document).ready(function() {
  const callCreateQrCode = (ticketId, VisitorId, currentTime, entryCount) => CreateQrCode(ticketId, VisitorId, currentTime, entryCount);
  window.callCreateQrCode = callCreateQrCode;

  const callTicketAlert  = (ticketId, VisitorId, refresh) => TicketAlert(ticketId, VisitorId, refresh);
  window.callTicketAlert = callTicketAlert;

  const callCreateMealCollectionQrCode = (AboNumber, email, eventId, VisitorId, currentTime) => CreateMealCollectionQrCode(AboNumber, email, eventId, VisitorId, currentTime);
  window.callCreateMealCollectionQrCode = callCreateMealCollectionQrCode;

  const callScanMealAlert  = (AboNumber, scanMealCollectionId, VisitorId, refresh) => ScanMealAlert(AboNumber, scanMealCollectionId, VisitorId, refresh);
  window.callScanMealAlert = callScanMealAlert;

  function CreateQrCode(ticketId, VisitorId, currentTime, entryCount){
    var qrcode = new QRCode(document.getElementById("qrcode"), {
    text: `host=${document.location.host}&ticket_id=${ticketId}&visitor_id=${VisitorId}&current_time=${currentTime}&entry_count=${entryCount}`,
    width: 300,
    height: 300,
    colorDark : "#000000",
    colorLight : "#ffffff",
    correctLevel : QRCode.CorrectLevel.H
    });
  }

  function TicketAlert(ticketId, VisitorId, refresh){
    $.ajax({
    type: "GET",
    dataType:"json",
    data: {'ticket_id' : ticketId, 'visitor_id' : VisitorId, 'refresh' : refresh },
    url: "/api/v1/ticket_sessions/get_ticket_alert",
    success: function(response){
      var isEmpty = Object.keys(response).length === 0;

      if (!isEmpty) {
        var alert_label   = response.alert_label;
        var alert_msg     = response.alert_msg;
        var failed_reason = `Reason : ${response.failed_reason}`;

        $('.alert-wrapper').removeClass('hide');
        $('.ticket-alert-label').addClass(`alert alert-${alert_label}`);
        $('.ticket-alert-text').addClass(`text-${alert_label}`);
        $('.ticket-alert-text').text(`${alert_msg}`);

        if (!(response.failed_reason == "")) {
          $('.failed-reason').text(`${failed_reason}`);
        }
      } else {
        $('.alert-wrapper').addClass('hide');
      }
    }
  });
  }

  function CreateMealCollectionQrCode(AboNumber, email, eventId, VisitorId, currentTime){
    var qrcode = new QRCode(document.getElementById("qrcode"), {
    text: `host=${document.location.host}&abo_number=${AboNumber}&email=${email}&event_id=${eventId}&visitor_id=${VisitorId}&current_time=${currentTime}`,
    width: 300,
    height: 300,
    colorDark : "#000000",
    colorLight : "#ffffff",
    correctLevel : QRCode.CorrectLevel.H
    });
  }

  function ScanMealAlert(AboNumber, scanMealCollectionId, VisitorId, refresh){
    $.ajax({
    type: "GET",
    dataType:"json",
    data: {'abo_number' : AboNumber, 'scan_meal_collection_id' : scanMealCollectionId, 'visitor_id' : VisitorId, 'refresh' : refresh },
    url: "/api/v2/scan_meal_collections/get_scan_meal_collection_alert",
    success: function(response){
      var isEmpty = Object.keys(response).length === 0;

      if (!isEmpty) {
        var alert_label   = response.alert_label;
        var alert_msg     = response.alert_msg;
        var failed_reason = `Reason : ${response.failed_reason}`;

        $('.alert-wrapper').removeClass('hide');
        $('.scan-meal-collection-alert-label').addClass(`alert alert-${alert_label}`);
        $('.scan-meal-collection-alert-text').addClass(`text-${alert_label}`);
        $('.scan-meal-collection-alert-text').text(`${alert_msg}`);

        if (!(response.failed_reason == "")) {
          $('.failed-reason').text(`${failed_reason}`);
        }
      } else {
        $('.alert-wrapper').addClass('hide');
      }
    }
  });
  }

})