$(document).ready(function(){
  searchPlatinumUser();
  selectPlatinumOptions();

  function searchPlatinumUser(){
    setTimeout(function() {
      $(".search-platinum-user").keyup(function(){
        var id = $(this).attr("data-id");
        var pase_id = $("#pase-center-id").val();
        var pase_att_id = $("#pase-attendance-id").val();

        $(`#upline-platinum-${id}`).val('');
        $(`#upline-platinum-${id}`).empty();
        $(`#upline-platinum-${id}`).append("<option value>Select Platinum Upline</option>");

        $.ajax({
          type: "GET",
          dataType:"json",
          data: { "abo_number" : $(this).val() },
          url: "/api/services/search_user_platinum",
          success: function(response){
            if (response.id != null) {
              let option = $("<option></option>");
                  option.attr("value", response.id);
                  option.text(`${response.name}`);
                  option.attr('selected', true);

              $(`#upline-platinum-${id}`).append(option);
            }
          }
        })
      })

    }, 500);
  }

  function selectPlatinumOptions(){
    $('.search-platinum-options').select2({
      ajax: {
        type: "GET",
        dataType:"json",
        url: "/api/services/search_platinum_options",
        delay: 1000,
        data: function (params) {
          return { 'q': { 'abo_number_cont': params.term } };
        },
        processResults: function (data) {
          return {
            results: data.platinums
          };
        }
      }
    });
  }

  // Add new attendance
  let guestCount = 0;
  
  // Function to add new attendance form
  $('.btn-add-more-attendance').click(function() {
    addNewAttendeeForm(++guestCount);
  });
   
  // Function to add a new attendee form
  function addNewAttendeeForm(count) {
    const newAttendee = `<div class="col-md-3">
      <div class="card card-add-attendance px-3 pb-3 rounded">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="text-dark font-weight-bold mb-3 pt-3 guest-int-title">Guest #${count}</h5>
          <button type="button" class="btn btn-remove-attendance text-danger">Remove</button>
        </div>
        <div class="add-attendance-form">
          <div class="add-attendance-item mb-3">
            <label class="form-label" for="name-attendance-${count}">
              Name
              <span class="text-danger">*</span>
            </label>
            <input type="text" name="user_pase_attendances[${count}][name]" required="required" id="name-attendance-${count}" class="form-control" placeholder="Name">
          </div>
          <div class="add-attendance-item mb-3">
            <label class="form-label" for="abo-number-attendance-${count}">
              ABO / APC Number
            </label>
            <input type="text" name="user_pase_attendances[${count}][abo_number]" id="abo-number-attendance-${count}" class="form-control search-platinum-user" placeholder="ABO / APC Number" data-id="${count}">
          </div>
          <div class="add-attendance-item mb-3">
            <label class="form-label" for="upline-platinum-${count}">
              Platinum Upline
            </label>
            <div><small>* Search by Platinum Upline ABO Number</small></div>
            <select name="user_pase_attendances[${count}][upline_platinum_id]" class="form-select form-control d-inline select2 search-platinum-options" id="upline-platinum-${count}" data-id="${count}">
              <option selected>Select Platinum Upline</option>
            </select>
          </div>
        </div>
      </div>
    </div>`;
   
    // Append the new attendee form
    $('.add-attendance').append(newAttendee);
  
    // Move the submit button to the new form
    $('.btn-submit-attendance').appendTo('.add-attendance .col-md-3:last .add-attendance-form');
    $('.btn-add-more-attendance').appendTo('.add-attendance .col-md-3:last .add-attendance-form');

    searchPlatinumUser();
    selectPlatinumOptions();
    updateGuestIntTitle();
   }
   
  // Event handler for removing attendance forms
  $('.add-attendance').on('click', '.btn-remove-attendance', function() {
    const totalForms = $('.add-attendance .col-md-3').length;
  
    if (totalForms === 1) {
      alert("At least one attendee form is required.");
      return;
    }
  
    if ($(this).closest('.col-md-3').find('.btn-submit-attendance').length > 0) {
      $(this).closest('.col-md-3').prev().find('.add-attendance-form').append($('.btn-submit-attendance'));
    }
  
    if ($(this).closest('.col-md-3').find('.btn-add-more-attendance').length > 0) {
      $(this).closest('.col-md-3').prev().find('.add-attendance-form').append($('.btn-add-more-attendance'));
    }
  
    $(this).closest('.col-md-3').remove();
    updateGuestCount();
    updateGuestIntTitle();
  });
   
  // Function to update the guest count after removing an attendee
  function updateGuestCount() {
    const totalForms = $('.add-attendance .col-md-3').length;
  
    if (totalForms === 1) {
      guestCount = 0;
      $('.add-attendance .col-md-3').find('h5').text(`Guest #1`);
      $('.add-attendance .col-md-3').find('[id^="name-attendance-"]').attr('id', `name-attendance-0`);
      $('.add-attendance .col-md-3').find('[for^="name-attendance-"]').attr('for', `name-attendance-0`);
      $('.add-attendance .col-md-3').find('[id^="abo-number-attendance-"]').attr('id', `abo-number-attendance-0`);
      $('.add-attendance .col-md-3').find('[for^="abo-number-attendance-"]').attr('for', `abo-number-attendance-0`);
      $('.add-attendance .col-md-3').find('[id^="upline-platinum-"]').attr('id', `upline-platinum-0`);
    }
  }

  function updateGuestIntTitle(){
    setTimeout(function() {
      var num = 1;
      $('.guest-int-title').each(function() {
        $(this).text(`Guest #${num}`)
        num = num+1;
      });
    }, 50);
  }
});
