$(document).ready(function() {
  $('.submit_search').click(function() {
    var date = $('.validate-date').val()

    if (date.length > 1) {
      split_strip = date.split(' - ')
      condition_split = split_strip.length == 2

      if (!condition_split) {
        alert('Format daterange is wrong!')
        return false
      }

      split_slice_first  = split_strip[0].split('/')
      split_slice_second = split_strip[1].split('/')

      condition1 = split_slice_first.length == 3 && split_slice_second.length == 3

      var condition2 = null;
      if (condition1) {
        condition2 = split_slice_first[2].length <= 3 || split_slice_second[2].length <= 3
      }

      if (!condition1 || condition2) {
        alert('Format daterange is wrong!')
        return false
      }
    }
  })

  $('#click_submit').click(function() {
    var date = $('.select-date').val()
    split_date = date.split('/')

    condition1 = split_date.length == 3
    condition2 = split_date[2].split('').length <= 3

    if (!condition1) {
      alert('Format date is wrong!')
      return false
    } else if (condition2) {
      alert('Format date is wrong!')
      return false
    }
  })
});
