$(document).ready(function() {
  $('#filter_whole_team_membership').change(function() {
    var value          = $(this).val();
    var balance_groups = $('#filter_balance_groups').is(':checked');

    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'membership_type' : value, 'balance_groups' : balance_groups },
      url: "/user/dashboard/membership_filter"
    });
  })
});
