$(document).ready(function() {
  $('#select2-pic').select2({
    ajax: {
      type: "GET",
      dataType:"json",
      url: '/admin/accounts/search_pic',
      delay: 1000,
      data: function (params) {
        var query = {
          abo_number: params.term,
          account_ids: $('.select2-pic').val()
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.accounts
        };
      }
    }
  });

  $('.checked-checkbox-pic').click(function(event) {
    var checked = $(this).is(':checked');

    if (checked) {
      picScanTicketSession($(this), 'scan_in_session', 'POST');
    } else {
      picScanTicketSession($(this), 'uncheck_attendance', 'DELETE');
    }
  })

  function picScanTicketSession($this, action, method) {
    var ticket_id  = $this.attr('data-ticket-id');
    var event_id   = $this.attr('data-event-id');
    var scope_page = $this.attr('data-scope-page');

    $.ajax({
      type: method,
      dataType:"json",
      data: { 'ticket_id' : ticket_id },
      url: `/${scope_page}/person_in_charges/${event_id}/${action}`,
      success: function(response){
        var description = response.description;
        var title       = response.title;
        var color       = response.color;
        var errors      = response.errors;

        $("#toast-title").attr("style", color);
        $("#toast-title").text(title);
        $("#toast-description").text(description);

        if (action == 'uncheck_attendance') {
          checkedSession(errors, ticket_id);
        } else {
          uncheckedSession(errors, ticket_id);
        }

        $('.toast').toast('show');
      }
    });
  }

  function uncheckedSession(errors, ticket_id) {
    if (errors) {
      $(`#ticket-${ticket_id}`).prop('checked', false);
    }
  }

  function checkedSession(errors, ticket_id) {
    if (errors) {
      $(`#ticket-${ticket_id}`).prop('checked', true);
    }
  }
})
