import Sortable from 'sortablejs';

$(document).ready(function() {
  refreshSortable();

  $('body').on('click', '.collapse-dropdown', function() {
    collapseListItem($(this));
  });

  function collapseListItem(list_group) {
    var data             = '';
    var account_id       = list_group.attr('data-account-id');
    var hash_child       = list_group.attr('data-hash-child');
    var data_json        = { 'id' : account_id };
    var data_level       = parseInt(list_group.attr('data-level')) + 1;
    var icon_arrow       = $(`.collapse-dropdown[data-account-id=${account_id}]`);
    var collapse_dropdown = icon_arrow.parent().find(`.collapse-parent-${account_id}`);
    var base_url          = $('.nested').data('base-url');
    var display_tmp_chlid = false;

    if (hash_child === 'true') {
      list_group.attr('data-hash-child', false);

      var main_path = window.location.pathname.includes("/user/") ? "user" : "admin";

      $.ajax({
        type: "GET",
        dataType:"json",
        data: data_json,
        url: `/${main_path}/organization_charts/${account_id}/get_children`,
        success: function(response){
          $.each( response.children, function( k, child ) {
            var child_id          = child.id;
            var user_id           = child.user_id;
            var recognition_name  = child.recognition_name;
            var abo_number        = child.abo_number;
            var pin               = child.pin;
            var tqg_level         = child.tqg_level;
            var expiry_style      = child.expired;
            var expiry            = child.expiry;
            var deactivated       = child.deactivated;
            var deactivated_style = child.deactivated_style;
            var has_children      = child.has_children;
            var base_url_linked   = base_url + `/${user_id}`;

            if (user_id != null) {
              var linked_detail = `<a href='${base_url_linked}' target='_blank'>${recognition_name}</a>`;
            } else {
              var linked_detail = recognition_name
            }

            if (has_children) {
              var icon          = 'zmdi zmdi-caret-right';
              var aria_expanded = false;
              var show_collapse = '';
              var style         = `cursor: pointer;`;
              var pl_child      = 'pl-2';
            } else {
              var aria_expanded = true;
              var icon          = '';
              var show_collapse = 'show';
              var style         = ``;
              var pl_child      = 'pl-3';
            }
            var child_style   = `padding-left: 15px`;

            var child_collaps = `<div class='collapse-parent-${child_id} list-group nested-sortable ${show_collapse}' id='account-${child_id}' data-account-id='${child_id}'></div>`;

            var collapse_child = `<div class="org-chart-icon list-group-item nested-${data_level} account-id-${child_id} mt-2" data-account-id='${child_id}' data-hash-child='${has_children}' data-level='${data_level}'>` +
                                  `<div class="d-flex">` +
                                    `<i class="collapse-dropdown glyphicon ${icon}" style='${style}' data-toggle='collapse' data-account-id='${child_id}' data-hash-child='${has_children}' data-level='${data_level}' aria-expanded='${aria_expanded}'></i>` +
                                    `<div class="w-100 ${pl_child}">` +
                                      linked_detail + `<b>(${abo_number})</b>` +
                                      `<div class="row">` +
                                        `<div class="col-md-4 f-12-5" style="${expiry_style}">Expiry: ${expiry}</div>` +
                                        `<div class="col-md-4 f-12-5">Pin: ${pin}</div>` +
                                        `<div class="col-md-4 f-12-5">TQG Level: ${tqg_level}</div>` +
                                      `</div>` +
                                      child_collaps +
                                    '</div>' +
                                  '</div>' +
                                `</div>`;

            data = data + collapse_child;
          });

          collapse_dropdown.html(data);
          collapse_dropdown.slideDown();
          icon_arrow.removeClass('zmdi-caret-right').addClass('zmdi-caret-down');

          refreshSortable();
        }
      });
    } else {
      list_group.attr('data-hash-child', true);
      display_tmp_chlid = true;
    }

    if (icon_arrow.hasClass('zmdi')) {
      if (collapse_dropdown.is(':hidden')) {
        icon_arrow.removeClass('zmdi-caret-right').addClass('zmdi-caret-down')
        collapse_dropdown.slideDown();
      } else {
        icon_arrow.removeClass('zmdi-caret-down').addClass('zmdi-caret-right')
        $.when( collapse_dropdown.slideUp() ).done(function() {
          if (display_tmp_chlid) {
            collapse_dropdown.html('');
            $(collapse_dropdown).attr('style', '');
          }
        });
      }
    }
  }

  function refreshSortable() {
    var nestedSortables = document.querySelectorAll('.nested-sortable');

    // Loop through each nested sortable element
    for (var i = 0; i < nestedSortables.length; i++) {
      new Sortable(nestedSortables[i], {
        group: 'nested',
        animation: 200,
        fallbackOnBody: true,
        swapThreshold: 0.20,
        emptyInsertThreshold: 10,
        forceFallback: true,
        fallbackTolerance: 5,

        onMove(event) {
          var to_account_id = event.to.dataset.accountId;

          if (to_account_id == undefined) {
            return false
          }
       },

        onEnd: function(event) {
          // removeEmptyListGroup();

          var from_account_id = event.from.dataset.accountId;
          var to_account_id   = event.to.dataset.accountId;

          var condition1 = (from_account_id == undefined) || (to_account_id == undefined);
          var condition2 = from_account_id == to_account_id

          if (condition1 || condition2) {
            return false
          } else {
            updateActiveSystemUpline(event);
            return true
          }
        },
      });
    }
  }

  function updateActiveSystemUpline(event){
    var account_dataset = event.item.dataset
    var account_id      = account_dataset.accountId;
    var active_system_upline_id = event.to.dataset.accountId;

    additionalOverlay();

    setTimeout(() => {
      var main_path = window.location.pathname.includes("/user/") ? "user" : "admin";

      $.ajax({
        type: "PUT",
        dataType:"json",
        data: {'account_id' : account_id, 'active_system_upline_id' : active_system_upline_id},
        url: `/${main_path}/collapse_organization_charts/update_active_system_upline`,
        error: function (xhr, ajaxOptions, thrownError) {
          removeAdditionalOverlay();
          var msg = "Something went wrong! Failed to update organization chart!"

          if (confirm(msg)) {
            location.reload();
            return true
          } else {
            location.reload();
            return false
          }
        },
        success: function(response){
          removeAdditionalOverlay();

          if (response.success_updated != null) {
            var reload_page = false;
            if (response.success_updated) {
              $("#toast-title").attr("style", "color: green;");
              $("#toast-title").text("Updated!");
              $("#toast-description").text("Organization chart successfully updated!");
            } else {
              $("#toast-title").attr("style", "color: red;");
              $("#toast-title").text("Failed to update!");
              $("#toast-description").text(`${response.error_msg}`);
              reload_page = true;
            }

            $('.toast').toast('show');

            if (reload_page) {
              setTimeout(() => {
                reloadPage(reload_page);
              }, 100);
            }

            updateIconCollapse(event, account_dataset);
          }
        }
      });
    }, 1000);
  }

  // function removeEmptyListGroup(){
  //   $('.list-group-root.well.nested-sortable').each(function() {
  //     if ($(this).children().length <= 0) {
  //       $(this).parent().remove();
  //     }
  //   });
  // }

  function reloadPage(reload){
    if (reload) {
      location.reload();
    }
  }

  function additionalOverlay() {
    $("#modal").modal('show');
    $('#modal').append('<div id="additional-overlay"><div id="additional-overlay-text">Updating.. <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="upload-loading-spinner-2"></span></div></div>');
  }

  function removeAdditionalOverlay(){
    $("#modal").modal('hide');
    $('#additional-overlay').remove();
  }

  function updateIconCollapse(event, account_dataset) {
    var from_parent_id = event.from.offsetParent.dataset.accountId

    if (from_parent_id != undefined) {
      var length_list_group_item = $(`.collapse-parent-${from_parent_id}`).find('.list-group-item').length;
      if (length_list_group_item <= 0) {
        $(`.account-id-${from_parent_id}`).find('i').removeClass('zmdi zmdi-caret-right zmdi zmdi-caret-down');
      }
    }

    var parent_id = event.to.offsetParent.dataset.accountId;
    var collapse_parent = $(`.collapse-parent-${parent_id}`);
    var i_parent = collapse_parent.parent().prev();

    var length_list_group_parent = collapse_parent.find('.list-group-item').length;
    if (length_list_group_parent <= 0) {
      $(`.account-id-${parent_id}`).find('i').first().removeClass('zmdi zmdi-caret-right zmdi zmdi-caret-down')
                                                     .addClass('zmdi zmdi-caret-down');
    } else {
      if (i_parent.parent().parent().find('.list-group-item').length > 0) {

        if (!i_parent.hasClass("zmdi zmdi-caret-down")) {
          i_parent.click();
          i_parent.removeClass('zmdi zmdi-caret-right zmdi zmdi-caret-down').addClass('zmdi zmdi-caret-down');
        }
      }
    }
  }
});
