import 'uppy/dist/uppy.min.css'
import Cropper from 'cropperjs';

import {
  Core,
  FileInput,
  Informer,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy'

function fileUploadCropperLocal(fileInput) {
  if (!$('#error-upload-gallery') == undefined) {
    $('#error-upload-gallery').remove();
  }

  const hiddenInput = document.querySelector('.upload-data'),
        form = $(fileInput).parents('form'),
        submitButton = document.getElementById('button-form-uppy'),
        formGroup = fileInput.parentNode,
        imagePreview = document.querySelector('#cropContainer img'),
        csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = Core({
    autoProceed: false,
    onBeforeFileAdded: (currentFile, files) => {
      currentFile['name'] = (new Date().getTime()) + "-" +  currentFile['name'];
    }
  })
  .use(FileInput, {
    target: formGroup,
  })
  .use(Informer, {
    target: formGroup,
  })
  .use(ThumbnailGenerator, {
    thumbnailWidth: 600,
  })
  .use(XHRUpload, { endpoint: '/images/upload', bundle: false, headers: { "X-CSRF-Token": csrfToken } })

  uppy.on('file-added', (file) => {
    loadingUploadBtn();
    imagePreviewLoader(imagePreview, file);

    $('#hidden-picture-id').prop('disabled', true);
    $('#achievement-img-form-gallery').modal('hide');

    setTimeout(() => {
      initCropperImage(imagePreview, file);
      removeLoadingUploadBtn();
      openCropperModal();
    }, 2500);
  })

  uppy.on('upload-error', (file, error, response) => {
    submitButton.removeAttribute("disabled")
    submitButton.innerHTML = "Save"
  })
}

function imagePreviewLoader(imagePreview, file) {
  // show preview of the image using URL from thumbnail generator
  const file2   = file.data;
  // var preview  = document.createElement("IMG");
  const reader = new FileReader();

  reader.onload = function () {
    imagePreview.src = reader.result;
  };

  if (file2) {
    reader.readAsDataURL(file2);
  }
}

function getCropOption(imagePreview) {
  var preview_height = imagePreview.height;
  var preview_width  = imagePreview.width;
  var imageData      = {}

  if ((preview_height >= 5000) && (preview_width >= 5000)) {
    imageData.max_width  = 5000;
    imageData.max_height = 5000;
    imageData.min_width  = 5000;
    imageData.min_height = 5000;
  } else {
    imageData.max_width  = preview_width;
    imageData.max_height = preview_height;
    imageData.min_width  = preview_width;
    imageData.min_height = preview_width;
  }

  var width = $(window).width();

  if (width < 375) {
    imageData.containerWidth = 270;
    imageData.containerHeight =  300;
    imageData.canvasWidth =  270;
    imageData.canvasHeight = 300;
  } else if (width < 425) {
    imageData.containerWidth = 325;
    imageData.containerHeight =  300;
    imageData.canvasWidth =  325;
    imageData.canvasHeight = 300;
  } else if (width < 768) {
    imageData.containerWidth = 375;
    imageData.containerHeight =  300;
    imageData.canvasWidth =  375;
    imageData.canvasHeight = 300;
  } else {
    imageData.containerWidth = 480;
    imageData.containerHeight =  320;
    imageData.canvasWidth =  480;
    imageData.canvasHeight = 320;
  }

  return imageData;
}

function initCropperImage(imagePreview, file) {
  var imageData  = getCropOption(imagePreview);
  var file_name  = file.name;
  var _mime_type = file.type;
  var get_blob   = "";

  var cropper = new Cropper(imagePreview, {
    aspectRatio: 1,
    viewMode: 1,
    guides: false,
    autoCropArea: .5,
    background: false,
    minContainerWidth: imageData.containerWidth,
    minContainerHeight: imageData.containerHeight,
    minCanvasWidth: imageData.canvasWidth,
    minCanvasHeight: imageData.canvasHeight,
    center: true,
    cropBoxResizable: true,
    dragMode: 'none',
    zoomOnWheel: false,
    crop: async event => {
      let imgUrl = await new Promise(resolve => {
        cropper.getCroppedCanvas({
          minWidth: imageData.min_width,
          minHeight: imageData.min_height,
          maxWidth: imageData.max_width,
          maxHeight: imageData.max_height,
          fillColor: '#fff',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        }).toBlob(blob => {
          get_blob = blob;
        }, "image/jpeg", 1)
      });
      $(".crop-container").each((i, item) => {
        item.style.backgroundImage = "url(" + imgUrl + ")";
      });
    }
  });

  // Cropper uploader
  $('#crop-upload-img').click(function(){
    $(this).off('click');
    $(this).on('click');
    $(this).prop('disabled', true);
    addLoadingSpinner($(this));
    additionalOverlay();

    $("#additional-overlay").ready(function() {
      var formData = new FormData();
          formData.append('image', get_blob, file_name);
          formData.append('achievement_submission_id', $('#achievment-submission-id').val());

      $.ajax({
        dataType: "script",
        type: "POST",
        data: formData,
        url: "/user/account_picture_galleries",
        processData: false,
        contentType: false,
        success: function(response){
          console.log('Upload success');
          actionAfterUpload();
          $('.cropper-hidden').attr('src', "");
          cropper.destroy();
          cropper.reset();
          cropper.clear();
        },
        error: function (response) {
          console.log('Upload error');
          actionAfterUpload();
          $('.cropper-hidden').attr('src', "");
          cropper.destroy();
          cropper.reset();
          cropper.clear();
        }
      })
    })
  });

  return cropper;
}

function openCropperModal() {
  $('#cropper-modal').modal({backdrop: 'static', keyboard: false});
}

function closeCropperModal() {
  $('#cropper-modal').modal('hide');
}

function addLoadingSpinner(elm) {
  $(elm).append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="upload-loading-spinner"></span>');
}

function removeLoadingSpinner() {
  $('#upload-loading-spinner').remove();
}

function loadingUploadBtn() {
  $('#btn-open-upload-file').append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="upload-loading-spinner-2"></span>');
  $('#btn-open-upload-file').text('Loading..');
  $('#btn-open-upload-file').prop('disabled', true);
  $("input[name='achivement_submission[account_picture_gallery_id]']").prop('disabled', true);
  $(".remove-picture-gallery").prop('disabled', true);
  $("#select-image-gallery").prop('disabled', true);
}

function removeLoadingUploadBtn() {
  $('#upload-loading-spinner-2').remove();
  $('#btn-open-upload-file').text('Upload File');
  $('#btn-open-upload-file').prop('disabled', false);
  $("input[name='achivement_submission[account_picture_gallery_id]']").prop('disabled', false);
  $(".remove-picture-gallery").prop('disabled', false);
  $("#select-image-gallery").prop('disabled', false);
}

function openGallery() {
  $('#cropper-modal').on('hidden.bs.modal', function () {
    $('#achievement-img-form-gallery').modal({backdrop: 'static', keyboard: false});
  })
}

function actionAfterUpload() {
  removeAdditionalOverlay()
  closeCropperModal();
  removeLoadingSpinner();
  openGallery();
  $('#achievement-img-form-gallery').unbind();
  $("#cropper-container").unbind();
  $("#additional-overlay").unbind();
  $('#crop-upload-img').prop('disabled', false);
  $('#upload-loading-spinner').remove();
}

function additionalOverlay() {
  $('.modal-content').append('<div id="additional-overlay"><div id="additional-overlay-text">Loading <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="upload-loading-spinner-2"></span></div></div>');
}

function removeAdditionalOverlay(){
  $('#additional-overlay').remove();
}

export default fileUploadCropperLocal