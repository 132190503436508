import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  AwsS3,
  Dashboard,
  // ImageEditor,
} from 'uppy'

function fileUploadDashboard(fileInput) {
  const uploadFileDashboard = Core({
    autoProceed: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: JSON.parse(fileInput.getAttribute('data-available-mimetype'))
    },
    onBeforeFileAdded: (currentFile, files) => {
      var limit_size = $('#file-size-limit').attr("data-limit");
      console.log(limit_size)
      console.log(currentFile)
      console.log(files)
      if (limit_size != undefined) {
        console.log(currentFile.data.size)
        console.log((parseInt(limit_size)*1024*1024))
        if (currentFile.data.size > (parseInt(limit_size)*1024*1024))
        {
          console.log('Max filesize exceded')
          alert("Max filesize exceded");
          validateImageSize();
          return false
          // return 'Max filesize exceded'
        }
      }
    }
  })
  .on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^public\/cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    const uploadDashInput = document.querySelector(fileInput.getAttribute('data-hidden-field'))
    uploadDashInput.value = JSON.stringify(uploadedFileData)

    // hide old image
    document.querySelectorAll(fileInput.getAttribute('data-old-preview'))[0].classList += ' d-none'
  })
  .use(AwsS3, {
    companionUrl: '/', // will call the presign endpoint on `/s3/params`
  })
  .use(Dashboard, {
    trigger: null,
    inline: true,
    target: fileInput.getAttribute('data-target'),
    replaceTargetContent: false,
    showProgressDetails: true,
    height: 330,
    metaFields: [
      { id: 'name', name: 'Name', placeholder: 'file name' },
      { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
    ],
    browserBackButtonClose: true
  })
  // .use(ImageEditor, {
  //   target: Dashboard,
  //   quality: 0.8, // for the resulting image, 0.8 is a sensible default
  //   id: 'image-upload',
  //   quality: 0.8,
  //   cropperOptions: {
  //     viewMode: 1,
  //     background: false,
  //     autoCropArea: 1,
  //     responsive: true,
  //     croppedCanvasOptions: {},
  //   },
  //   actions: {
  //     revert: true,
  //     rotate: true,
  //     granularRotate: true,
  //     flip: true,
  //     zoomIn: true,
  //     zoomOut: true,
  //     cropSquare: true,
  //     cropWidescreen: true,
  //     cropWidescreenVertical: true,
  //   },
  // })
  // .use(Tus, { endpoint: 'https://master.tus.io/files/' });
}

export default fileUploadDashboard
