const callrecipientAboLookup = () => recipientAboLookup();
window.callrecipientAboLookup = callrecipientAboLookup;

$(document).ready(function() {
  bulkTransfer();
});

function recipientAboLookup() {
  var namespace  = $("#transfer-form").attr("data-namespace");
  var table_name = $("#transfer-form").attr("data-table-name");

  initialUserDownlines(namespace, table_name);
  viewMoreAllList(namespace, table_name);
  searchAboRecipient(namespace, table_name);
  viewMoreListSearchResult(namespace, table_name);
  seachRecipientByEmail();
  searchRecipientByAbo();
  closeSearchRecipient();
}

function seachRecipientByEmail() {
  $(".btn-search-email").click(function(e){
    e.preventDefault();
    $(".search-with-abo").addClass("d-none");
    $(".search-with-email").removeClass("d-none");
    $("#input-search-abo-number").val("");
  });
}

function searchRecipientByAbo() {
  $(".btn-search-abo").click(function(e){
    e.preventDefault();
    $(".search-with-abo").removeClass("d-none");
    $(".search-with-email").addClass("d-none");
    $("#input-search-email").val("");
  });
}

function closeSearchRecipient(){
  $(".btn-close-search").click(function(){
    $(this).addClass("d-none");
    $(".abo-section.all").removeClass("d-none");
    $(".abo-section.result").addClass("d-none");
    $(".abo-section.result .list-result").children().remove();

    stopSpinner();
    $(".search-transfer-recipient").val("");
    $(".modal-footer-text").text("");
    $(".input-check-abo").prop("checked", false);
    $("#submit-transfer-form").attr('disabled', true);
  });
}

function selectAboRecipient() {
  $(".input-check-abo").change(function(){
    if($(this).is(":checked")){
      var name = $(this).parent().find(".abo-item .abo-name").text().toUpperCase();

      $("#hidden-input-recipient").val($(this).val());
      $("#submit-transfer-form").attr('disabled', false);
      $(".modal-footer-text").html(`Are you sure want to transfer <b>1</b> ticket to <b>${name}</b>?`);
    }
  })
  $(".abo-section .abo-item").click(function(){
    $(".abo-section .abo-item").removeClass("selected");
    $(this).addClass("selected");
  });
}

function appendElementAbo(element) {
  var new_html = `<li>
    <input type="radio" id="input-check-abo-${element.id}" class="input-check-abo" name="input-check-abo" value="${element.id}">
    <label class="abo-item" for="input-check-abo-${element.id}">
      <div class="abo-wrapper d-flex align-items-center">
        <div class="abo-avatar mr-2">
          ${element.avatar_tag}
        </div>
        <div class="abo-title w-100">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h2 class="text-uppercase mb-0 abo-name">${element.recognition_name}</h2>
            <span class="abo-number">${element.abo_number}</span>
          </div>
          <h2 class="abo-email">${element.email}</h2>
        </div>
      </div>
    </label>
  </li>`
  return new_html;
}

function initialUserDownlines(namespace, table_name) {
  $.ajax({
    type: "GET",
    dataType:"json",
    url: `/${namespace}/${table_name}/user_downlines`,
    success: function(response){
      $("#btn-view-more-list-all").attr("data-next-page", response.next_page);
      $(".abo-section.all").removeClass("d-none");
      response.accounts.forEach((element) => {
        var append_li = appendElementAbo(element);
        $(".abo-section.all .list-all").append(append_li);

        showHideMoreAll(response.last_page);
      });
      stopSpinner();
      selectAboRecipient();

      if (response.accounts.length < 1) {
        showHideMoreAll(true);
      }
    }
  })
}

function viewMoreAllList(namespace, table_name) {
  $("#btn-view-more-list-all").click(function(){
    loadSpinner();
    var page  = $("#btn-view-more-list-all").attr("data-next-page");

    $.ajax({
      type: "GET",
      dataType:"json",
      url: `/${namespace}/${table_name}/user_downlines?page=${page}`,
      success: function(response){
        response.accounts.forEach((element) => {
          $("#btn-view-more-list-all").attr("data-next-page", response.next_page);

          var append_li = appendElementAbo(element);
          $(".abo-section.all .list-all").append(append_li);

          stopSpinner();
          showHideMoreAll(response.last_page);
        });
        selectAboRecipient();

        if (response.accounts.length < 1) {
          showHideMoreAll(true);
        }
      }
    })
  })
}

function searchAboRecipient(namespace, table_name) {
  var last_search_params = $(".search-transfer-recipient").val();
  $(".search-transfer-recipient").keyup(function(){
    setTimeout(() => {
      var $this = $(this);
      var search_text = $(this).val();

      $this.parent().find(".btn-close-search").removeClass("d-none");

      if (search_text != last_search_params) {
        $(".abo-section.all").addClass("d-none");
        $(".abo-section.result").addClass("d-none");

        loadSpinner();
        $(".modal-footer-text").text("");
        $("#submit-transfer-form").attr('disabled', true);
        $(".input-check-abo").prop("checked", false);

        last_search_params = search_text;
        var search_text_length = search_text.length;

        if (search_text_length > 1) {
          var search_key = $(this).attr("name");
          if (search_key == "search_abo_number") {
            data_search = { 'search_abo_number' : $(this).val() }
          } else {
            data_search = { 'search_email' : $(this).val() }
          }

          $.ajax({
            type: "GET",
            dataType:"json",
            data: data_search,
            url: `/${namespace}/${table_name}/search_user_recipient`,
            success: function(response){
              $(".abo-section.result .list-result").html("");
              if(response.accounts.length > 0) {
                response.accounts.forEach((element) => {
                  $("#btn-view-more-list-result").attr("data-next-page", response.next_page);

                  var append_li = appendElementAbo(element);
                  $(".abo-section.result .list-result").append(append_li);

                  stopSpinner();
                  showHideMoreResult(response.last_page);
                });
              } else {
                stopSpinner();
                showHideMoreResult(true);
                var html = "<li class='text-center'><h2 class='font-18 py-5'>No Results Found</h2></li>";
                $(".abo-section.result .list-result").html(html);
              }
              selectAboRecipient();
              $(".abo-section.result").removeClass("d-none");
            }
          })
        } else {
          stopSpinner();
          $(".abo-section.all").removeClass("d-none");
        }
      }
    }, 2000);
  });
}

function viewMoreListSearchResult(namespace, table_name) {
  $("#btn-view-more-list-result").click(function(){
    loadSpinner();

    var page  = $("#btn-view-more-list-result").attr("data-next-page");

    var search_key = $(".search-transfer-recipient").attr("name");
    if (search_key == "search_abo_number") {
      data_search = { 'search_abo_number' : $(".search-transfer-recipient").val() }
    } else {
      data_search = { 'search_email' : $(".search-transfer-recipient").val() }
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: data_search,
      url: `/${namespace}/${table_name}/search_user_recipient?page=${page}`,
      success: function(response){
        response.accounts.forEach((element) => {
          $("#btn-view-more-list-result").attr("data-next-page", response.next_page);

          var append_li = appendElementAbo(element);
          $(".abo-section.result .list-result").append(append_li);

          stopSpinner();
          showHideMoreResult(response.last_page);
        });

        selectAboRecipient();
        if (response.accounts.length < 1) {
          showHideMoreResult(true);
        }
      }
    })
  })
}

function loadSpinner() {
  $(".spinner-wrap").addClass("d-flex").removeClass("d-none");
}

function stopSpinner() {
  $(".spinner-wrap").addClass("d-none").removeClass("d-flex");
}

function bulkTransfer() {
  $(".check-input-obj").click(function(){
    if($(".check-input-obj:checked").length >= 2){
      $("#btn-bulk-transfer").removeClass("disabled-btn");
    } else {
      $("#btn-bulk-transfer").addClass("disabled-btn");
    }
  })

  $(".check-all-ticket, .check-all-meal-voucher").click(function(){
    if($(this).is(":checked")){
      $(".check-input-obj").prop("checked", true);
      $("#btn-bulk-transfer").removeClass("disabled-btn");
    } else {
      $(".check-input-obj").prop("checked", false);
      $("#btn-bulk-transfer").addClass("disabled-btn");
    }
  })

  $("#btn-bulk-transfer").click(function(){
    var object_ids   = [];
    var inventory_id = $(this).attr("data-inventory-id");
    var namespace    = $(this).attr("data-namespace");
    var table_name   = $(this).attr("data-table-name");

    $('.check-input-obj:checked').each(function () {
      object_ids.push($(this).val());
    });

    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'object_ids' : object_ids },
      url: `/${namespace}/${table_name}/${inventory_id}/bulk_transfer`
    })
  });
}

function showHideMoreAll(last_page) {
  if (last_page) {
    $("#btn-view-more-list-all").addClass("d-none");
  } else {
    $("#btn-view-more-list-all").removeClass("d-none");
  }
}

function showHideMoreResult(last_page) {
  if (last_page) {
    $("#btn-view-more-list-result").addClass("d-none");
  } else {
    $("#btn-view-more-list-result").removeClass("d-none");
  }
}
