$(document).ready(function() {
  $('#select_super_core_qualifications').change(function() {
    var period        = $(this).val()
    var redirect_back = $(this).data('redirect_back')

    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'period' : period, 'redirect_back' : redirect_back },
      url: "/user/super_core_qualifications/filter_super_core"
    });
  })
});
