$(document).ready(function(){
  $("#next-btn").attr('disabled', true);
  $("input.terms").change(function(){
    var agree_input = $(this);
    var next_btn    = $("#next-btn");

    if(agree_input.prop("checked")){
      next_btn.prop("disabled", false);
    }else{
      next_btn.prop("disabled", true);
    }
  })

  $("#next-btn").click(function(){
    emailValidation(true);
  });

  $("#form-co-applicant").hide()
  $("input[name='user[co_applicant]").change(function(){
    if($("#co-applicant-no").is(':checked')){
      $("#form-co-applicant").hide();
    }else if($("#co-applicant-yes").is(':checked')){
      $("#form-co-applicant").show();
    }
  });

  $('input[name="user[co_applicant]"]').change(function(){
    var add_co_applicant = $('input[name="user[co_applicant]"]:checked').val();

    if ("Yes") {
      $('#select-partner-nationality').attr('required', true);
    } else {
      $('#select-partner-nationality').attr('required', false);
    }
  }).change();

  $(".membership__card").click(function () {
    const input_radio = $(this).find("input")
    $(".membership__card").removeClass("membership__card--selected")
    
    input_radio.prop("checked", true)
    if (input_radio.is(":checked")) $(this).toggleClass("membership__card--selected")
  })

  $(".signup-flows__link").on("click", function() {
    var clicked_step = $(this).attr('id');
    var validations  = getStepValidation(clicked_step);

    // VALIDATIONS
    var form_valid = [];
    if (validations.includes('step-1')) {
      var validation = stepOneValidation();
      if (validation === true) {
        form_valid.push(true);
      } else {
        form_valid.push(false);
      }
    }

    if (validations.includes('step-2')) {
      var validation = stepTwoValidation();
      if (validation === true) {
        form_valid.push(true);
      } else {
        form_valid.push(false);
      }
    }

    if (validations.includes('step-3')) {
      var validation = stepThreeValidation();
      if (validation === true) {
        form_valid.push(true);
      } else {
        form_valid.push(false);
      }
    }

    if (validations.includes('step-4')) {
      var validation = stepFourValidation();
      if (validation === true) {
        form_valid.push(true);
      } else {
        form_valid.push(false);
      }
    }

    if (!form_valid.includes(false)) {
      continueNextStep($(this));
    } else {
      return false;
    }
  });

  function getStepValidation(clicked_step) {
    var validations = [];
    if ($('.signup-flows__step-one').hasClass('active')) {
      validations.push('step-1');
    }

    if ($('.signup-flows__step-two').hasClass('active')) {
      validations.push('step-2');
    }

    if ($('.signup-flows__step-three').hasClass('active')) {
      validations.push('step-3');
    }

    if ($('.signup-flows__step-four').hasClass('active')) {
      // validations.push('step-4');
    }

    // if (clicked_step === 'applicant-information') {
    // }

    if (clicked_step === 'mailing-information') {
      validations.push('step-1');
    }

    if (clicked_step === 'identify-sponsor-mentor') {
      validations.push('step-1');
      validations.push('step-2');
    }

    if (clicked_step === 'select-membership-package') {
      validations.push('step-1');
      validations.push('step-2');
      validations.push('step-3');
    }
    return validations;
  }

  function continueNextStep(elm){
    const items = $(".signup-flows__item")
    const links = $(".signup-flows__link")
    const stepNumber = $(".signup-flows__step-number")
    const current = $(elm).find(".signup-flows__step-number")[0]
    items.removeClass("signup-flows__active-flows")
    stepNumber.removeClass("signup-flows__step-number--active")


    if (current.innerText != 1) {
      handlerActiveStep(items, "signup-flows__active-flows")
      handlerActiveStep($(".signup-flows__step-number"), "signup-flows__step-number--active")
    }
    if (current.innerText == links.length) current.classList.add("signup-flows__step-number--active")

    $(elm).closest("li").addClass("signup-flows__active-flows");

    function handlerActiveStep (element, activeClass) {
       element.filter(el => el != (current.innerText - 1) && el < (current.innerText - 1)).addClass(activeClass)
    }

    disableInableStep(current.innerText);
  }

  function disableInableStep(innerText){
    var disable_buttons = [];
    var active_buttons  = [];

    if (innerText == 1) {
      disable_buttons.push('step-2', 'step-3', 'step-4');
      // active_buttons.push('step-2'); dont add step-2 to active_button cause make an issue
    }

    if (innerText == 2) {
      disable_buttons.push('step-4');
      active_buttons.push('step-1', 'step-3');
    }

    if (innerText == 3) {
      disable_buttons.push('step-1');
      active_buttons.push('step-2', 'step-4');
    }

    if (innerText == 4) {
      disable_buttons.push('step-1', 'step-2');
      active_buttons.push('step-3');
    }

    if (disable_buttons.includes('step-1')) {
      $('#applicant-information').attr('style', 'pointer-events: none');
    }

    if (disable_buttons.includes('step-2')) {
      $('#mailing-information').attr('style', 'pointer-events: none');
    }

    if (disable_buttons.includes('step-3')) {
      $('#identify-sponsor-mentor').attr('style', 'pointer-events: none');
    }

    if (disable_buttons.includes('step-4')) {
      $('#select-membership-package').attr('style', 'pointer-events: none');
    }

    if (active_buttons.includes('step-1')) {
      $('#applicant-information').attr('style', '');
    }

    if (active_buttons.includes('step-2')) {
      $('#mailing-information').attr('style', '');
    }

    if (active_buttons.includes('step-3')) {
      $('#identify-sponsor-mentor').attr('style', '');
    }

    if (active_buttons.includes('step-4')) {
      $('#select-membership-package').attr('style', '');
    }
  }

  $("#avatar_uploader").change(function(){
    PreviewImage(this);
  });

  function PreviewImage(input) {
    if (input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        $('.profile__avatar-previewer').attr('src', e.target.result);
        if ($('#avatar-data-uri').length != 0) {
          $('#avatar-data-uri').val(e.target.result);
        }
      };

      reader.readAsDataURL(input.files[0]);
    }

    else console.error("Ups Something Error")
  }

  // VALIDATIONS
  $(".signup-flows__step-one .btn-step-one").click(function(){
    var validation = stepOneValidation();
    if (validation === true){
      aboNumberValidation();
    }
  });

  function stepOneValidation(){
    var form = $("#step-one");
    form.validate({
      rules: {
        'user[name]': { required: true },
        'user[applicant_profile_attributes][name]': { required: true },
        'user[applicant_profile_attributes][distributorship_name]': { required: true },
        'user[account_attributes][recognition_name]': { required: true },
        'user[account_attributes][pin_id]': { required: true },
        'user[account_attributes][level_id]': { required: true },
        'user[applicant_profile_attributes][dob]': { required: true },
        'user[applicant_profile_attributes][nationality_id]': { required: true },
        'user[applicant_profile_attributes][nric]': { required: true },
        // 'user[applicant_profile_attributes][other_id_no]': {
        //   required: function(e) {
        //     return $("#other-id-type-register").val() != '' ? true : false
        //   }
        // },
        // 'user[applicant_profile_attributes][other_id_type]': {
        //   required: function(e) {
        //     return $("#other-id-number-register").val() != '' ? true : false
        //   }
        // },
        'user[applicant_profile_attributes][race]': { required: true },
        'user[applicant_profile_attributes][gender]': { required: true },
        'user[applicant_profile_attributes][marital_status]': { required: true },
        'user[account_attributes][partner_attributes][applicant_profile_attributes][name]': { required: true },
        'user[account_attributes][partner_attributes][email]': { required: true },
        'user[account_attributes][partner_attributes][applicant_profile_attributes][dob]': { required: true },
        'user[account_attributes][partner_attributes][applicant_profile_attributes][nric]': { required: true },
        // 'user[account_attributes][partner_attributes][applicant_profile_attributes][other_id_type]': {
        //   required: function(e){
        //     return $("#other-id-number-1-register").val() != '' ? true : false
        //   }
        // },
        // 'user[account_attributes][partner_attributes][applicant_profile_attributes][other_id_no]': {
        //   required: function(e){
        //     return $("#other-id-type-co-register").val() != '' ? true : false
        //   }
        // },
        'user[account_attributes][partner_attributes][applicant_profile_attributes][race]': { required: true },
      },
      messages: {
        fullName: "Please specify your full name"
      }
    });
    return form.valid();
  }

  $(".signup-flows__step-two .btn-step-two").click(function(){
    var validation = stepTwoValidation();
    if (validation === true){
      nextValidStepTwo();
    }
  });

  function stepTwoValidation(){
    var form = $("#step-two");
    form.validate({
      rules: {
        'user[account_attributes][account_address_attributes][address1]': { required: true },
        'user[account_attributes][account_address_attributes][city]': { required: true },
        'user[account_attributes][account_address_attributes][postcode]': { required: true },
        'user[account_attributes][account_address_attributes][state]': { required: true },
        'user[account_attributes][account_address_attributes][country_id]': { required: true },
        'user[account_attributes][account_address_attributes][mobile_phone_1]': { required: true },
        'user[account_attributes][account_address_attributes][mobile_phone_1_code]': { required: true },
        'user[account_attributes][account_address_attributes][home_number]': { 
          required: function(e) {
            return $("#home_number_code").val() != '' ? true : false
          }
         },
        'user[account_attributes][account_address_attributes][home_number_code]': { 
          required: function(e) {
            return $("#home-contact").val() != '' ? true : false
          }
         },
      }
    });
    return form.valid();
  }

  function nextValidStepTwo(){
    $(".signup-flows__tabs > .nav-item > .nav-link.active").parent().next("li").find("a").trigger("click");
    $('#identify-sponsor-mentor').tab("show");
  }

  $(".signup-flows__step-three .btn-step-three").click(function(){
    var validation = stepThreeValidation()
    if (validation === true){
      nextValidStepThree();
    }
  });

  function stepThreeValidation(){
    var form = $("#step-three");
    form.validate({
      rules: {
        'user[account_attributes][abo_number]': { required: true },
        'user[account_attributes][platinum_recognition_name]': { required: true },
        // 'user[account_attributes][platinum_abo_number]': { required: true },
        'user[account_attributes][sponsor_abo_number]': { required: true },
        'user[active_system_upline_abo_number]': { required: true },
        'platinum_abo_number': { required: true },
        // 'active_system_upline_abo_number': { required: true },
        'active_system_upline_name': { required: true },
        'temp_platinum_name': { required: true }
      }
    });
    return form.valid();
  }

  function nextValidStepThree(){
    $(".signup-flows__tabs > .nav-item > .nav-link.active").parent().next("li").find("a").trigger("click");
    $('#select-membership-package').tab("show");
  }

  $(".signup-flows__step-four .btn-step-four").click(function(){
    var validation = stepFourValidation();
    if (validation === true){
      $(this).prop('disabled', true);

      var signUpForm = $("form#new_user");
      var fields     = '';

      $("input[name^=user]").add($("select[name^=user]")).each(function(){
        if (($(this).prop("type") == "radio") && ($(this).prop("checked") == false)){ return; }
        fields += '<input type="hidden" value="' + $(this).val() + '" name="' + $(this).prop("name") + '">';
      })

      signUpForm.append(fields);
      signUpForm.submit();
      return false;
    }
  });

  function stepFourValidation(){
    var form = $("#step-four");
    form.validate({
      rules: {
        'user[membership_package_id]': { required: true },
        'required-agreement': { required: true }
      },
      messages: {
        'user[membership_package_id]': 'Please Choose a Package',
        'required-agreement': 'This field is required'
      },
      errorPlacement: function (error,element) {
        element.data( "toggle", "tooltip" );
        element.data( "placement", "top" );
        element.prop( "title", error.text() );
        element.tooltip('show');
      }
    });
    return form.valid();
  }

  $("#submit-sign-up").attr('disabled', true);
  $("#terms-member").change(function(){
    if ($(this).is(':checked')) {
      $("#submit-sign-up").prop('disabled', false);
    } else {
      $("#submit-sign-up").prop('disabled', true);
    }
  });

  $('#two-step').find('#country').change(function() {
    if ($(this).children('option:selected').html() == 'Malaysian' || $('#two-step').find('#country').val() == '1'){
      $('.mailing-information-state').removeClass('d-none')
      $('.mailing-information-state select#state').attr('required')
    } else {
      $('.mailing-information-state').addClass('d-none')
      $('.mailing-information-state select#state').val('nil')
      $('.mailing-information-state select#state').removeAttr('required')
    }
  })

  function signUpFormValidation(){
    var form = $("#sign-up-form");
    form.validate({
      rules: {
        'name': { required: true },
        'abo_number': { required: true },
        'email': { required: true, email: true },
        'confirm_email': { equalTo: '#email' },
        'password': { required: true, minlength: 6 },
        'password_confirmation': { equalTo: '#password' },
        'required-agreement': { required: true }
      },
      messages: {
        'name': "Please specify your full name",
        'password': "Password should be at least 6 characters",
        'password_confirmation': "Please enter same password as above",
      },
      errorPlacement: function (error,element) {
        if(element.attr("name") == "required-agreement") {
          element.data( "toggle", "tooltip" );
          element.data( "placement", "top" );
          element.prop( "title", error.text() );
          element.tooltip('show');
        } else {
          error.insertAfter(element);
        }
      }
    });
    if (form.valid() === true){
      form.submit();
    }
  }

  function aboNumberValidation(){
    var abo_number = $('#abo-number').val();
    var id         = $('#account-obj-id').val();
    if (id == undefined) {
      id = null;
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: {'abo_number' : abo_number, 'id': id},
      url: "/api/services/abo_number_validation",
      error: function (xhr, ajaxOptions, thrownError) {
        abuNumberError();
      },
      success: function(response){
        if (response['result'] && response['valid_length'] == false) {
          $("#abo-number-error").text("");
          emailValidation();
        } else if (response['result'] == false) {
          abuNumberError(response);
        } else if (response['valid_length'] == true) {
          abuNumberError(response);
        }
      }
    });
  }

  function abuNumberError(response){
    var abo_number = $('#abo-number').val();
    $("#abo-number-error").attr('style', '');
    if ($("#abo-number-error").text().length < 1) {
      if (response['result'] == false) {
        $("#abo-number-error").append("ABO Number already exist.");
      } else if (response['valid_length'] == true) {
        $("#abo-number-error").append("ABO Number length must be 7-10");
      }
    }
    return false;
  }

  function emailValidation(validate_sign_up = false){
    var email = $('#email').val();
    var user_id    = $('#user-obj-id').val();
    var partner_id = $('#partner-obj-id').val();

    if (user_id == undefined) {
      user_id = null;
    }

    if (partner_id == undefined) {
      partner_id = null;
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'email' : email, 'user_id' : user_id, 'partner_id' : partner_id },
      url: "/api/services/email_validation",
      error: function (xhr, ajaxOptions, thrownError) {
        emailError();
      },
      success: function(response){
        if (response['email']['valid']) {
          $(".email-sign-up-error").text("");
          if (validate_sign_up) {
            signUpFormValidation();
          } else {
            // callStep2();
            coApplicantEmailValidation();
          }
        } else {
          emailError(response['email']['message']);
        }
      }
    });
  }

  function emailError(message = "Email already exist."){
    $(".email-sign-up-error").attr('style', '');
    $(".email-sign-up-error").text('')
    $(".email-sign-up-error").append(message);
    return false;
  }

  function coApplicantEmailValidation(){
    var email = $('#co-applicant-email').val();
    var user_id    = $('#user-obj-id').val();
    var partner_id = $('#partner-obj-id').val();

    if (user_id == undefined) {
      user_id = null;
    }

    if (partner_id == undefined) {
      partner_id = null;
    }

    if (email != "") {
      $.ajax({
        type: "GET",
        dataType:"json",
        data: { 'email' : email, 'user_type': 'partner', 'user_id' : user_id, 'partner_id' : partner_id },
        url: "/api/services/email_validation",
        error: function (xhr, ajaxOptions, thrownError) {
          coApplicantEmailError();
        },
        success: function(response){
          if (response['email']['valid']) {
            $(".email-co-applicant-sign-up-error").text("");
            callStep2();
          } else {
            coApplicantEmailError(response['email']['message']);
          }
        }
      });
    } else {
      callStep2()
    }
  }

  function coApplicantEmailError(message = "Co-Applicant email already exist."){
    $(".email-co-applicant-sign-up-error").attr('style', '');
    $(".email-co-applicant-sign-up-error").text('');
    $(".email-co-applicant-sign-up-error").append(message);
    return false;
  }

  function callStep2(){
    $(".signup-flows__tabs > .nav-item > .nav-link.active").parent().next("li").find("a").trigger("click");
    $("#mailing-information").tab("show");
  }
});