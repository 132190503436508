$(document).ready(function() {
  $('#filter_balance_groups').change(function() {
    $('#alert_message').removeClass('d-none');

    var membership_type = $('#filter_whole_team_membership').val();
    var value           = $(this).is(':checked');
    var url             = $(this).attr("data-url");

    $.ajax({
      type: "GET",
      dataType:"script",
      data: { 'balance_groups' : value, 'membership_type' : membership_type },
      url: url
    });
  })
});
