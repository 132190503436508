import fileMultiUploadLocal from './fileMultiUploadLocal.js'
import fileMultiUpload from './fileMultiUpload.js'

$(document).ready(function() {
  var rails_env = document.querySelector('div[data-env]').dataset.env;

  document.querySelectorAll('#input-weigh-out-video').forEach(fileInput => {
    if (rails_env == 'development') {
      fileMultiUploadLocal(fileInput)
    } else {
      fileMultiUpload(fileInput)
    }
  })

  setTimeout(() => {
    // warningEmailAbo();
    openSubmitBtn();

    var checked_individual_registration_type = $("input[name='challenge_submission[registration_type]']:checked").val();
    if (checked_individual_registration_type != undefined) {
      reloadChallengeSubmission(checked_individual_registration_type);
    }

    var checked_team_registration_type = $("input[name='team_challenge_submission[registration_type]']:checked").val();
    if (checked_team_registration_type != undefined) {
      reloadChallengeSubmission(checked_team_registration_type);
    }
  }, 100)

  if ($("input[name='ad_hoc_lucky_draw_user[registration_type]']").length > 0) {
    showHideAboNumber();
  }

  var input_registration_type = $("input[name='ad_hoc_lucky_draw_user[registration_type]']");
  input_registration_type.change(function() {
    openSubmitBtn();
    showHideAboNumber();
    removeDisableRegisterBehalfEmail();
    $('#error-find-email-downline').text('');
    $('#error-find-downline').text('');
    $('#warning-find-email-behalf').text('');

    var registration_type = $("input[name='ad_hoc_lucky_draw_user[registration_type]']:checked").val();

    if (registration_type == 'register_myself') {
      $('#form-register-myself').removeClass('hide');
      $('#form-register-behalf-other').addClass('hide');

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('.input-register-behalf').attr('disabled', true);
      $('#behalf-abo-number').removeAttr('required');
      $('.required-behalf-input').addClass('hide');
      $('#set-registration_by').val(0);
    } else {
      var register_by_abo = $("input[name=register_by_abo]:checked").val();

      if (register_by_abo == 'true') {
        disableRegisterBehalfEmail();
        $('.required-behalf-input').addClass('hide');
        $('#behalf-abo-number').attr('required', true);
        $('#behalf-abo-number').removeAttr('disabled');
        $('#required-behaf-abo-icon').removeClass('hide');
      } else {
        $('.required-behalf-input').removeClass('hide');
        $('#behalf-abo-number').attr('disabled', true);
        $('#required-behaf-abo-icon').addClass('hide');
      }

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('#form-register-myself').addClass('hide');
      $('#form-register-behalf-other').removeClass('hide');
      $('#set-registration_by').val(0);
    }
  })

  var registration_type_challenge_submission = $("input[name='challenge_submission[registration_type]']");
  registration_type_challenge_submission.change(function() {
    openSubmitBtn();
    showHideAboNumber();
    removeDisableRegisterBehalfEmail();

    $('#behalf-participant-name').removeAttr('disabled');

    $('#error-find-email-downline').text('');
    $('#error-find-downline').text('');
    $('#warning-find-email-behalf').text('');

    var registration_type = $("input[name='challenge_submission[registration_type]']:checked").val();

    if (registration_type == 'register_myself') {
      $('#form-register-myself').removeClass('hide');
      $('#form-register-behalf-other').addClass('hide');

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('.input-register-behalf').attr('disabled', true);
      $('#behalf-abo-number').removeAttr('required');
      $('.required-behalf-input').addClass('hide');
      $('#set-registration_by').val(0);

    } else {
      var register_by_abo = $("input[name=register_by_abo]:checked").val();

       if (register_by_abo == 'true') {
        disableRegisterBehalfEmail();
        $('.required-behalf-input').addClass('hide');
        $('#behalf-abo-number').attr('required', true);
        $('#behalf-abo-number').removeAttr('disabled');
        $('#required-behaf-abo-icon').removeClass('hide');
      } else {
        $('.required-behalf-input').removeClass('hide');
        $('#behalf-abo-number').attr('disabled', true);
        $('#required-behaf-abo-icon').addClass('hide');
      }

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('#form-register-myself').addClass('hide');
      $('#form-register-behalf-other').removeClass('hide');
      $('#set-registration_by').val(0);
    }
  })

  // after reload finish
  var registration_type = $("input[name='team_challenge_submission[registration_type]']:checked").val();
  if (registration_type == 'register_myself' || registration_type == 'team_leader') {
    if (registration_type == 'register_myself') {
      $('#default-leader-team').attr('disabled', true);
      $('#team-leader-input').removeClass('hide');
      $('#select-team-leader').removeAttr('disabled', true);

    } else {
      $('#default-leader-team').removeAttr('disabled', true);
      $('#team-leader-input').addClass('hide');
      $('#select-team-leader').attr('disabled', true);
    }

  } else {
    $('#default-leader-team').attr('disabled', true);
    $('#team-leader-input').removeClass('hide');
    $('#select-team-leader').removeAttr('disabled', true);

    if ($('#form-challenge-submission.team-challenge-form').length > 0) {
      $('#behalf-participant-name').removeAttr('disabled');
    }
    openParticipantChallengeCategory();
  }
  //

  var registration_type_team_challenge_submission = $("input[name='team_challenge_submission[registration_type]']");
  changeTeamLeader();
  registration_type_team_challenge_submission.change(function() {
    openSubmitBtn();
    showHideAboNumber();
    removeDisableRegisterBehalfEmail();

    $('#behalf-participant-name').removeAttr('disabled');

    $('#error-find-email-downline').text('');
    $('#error-find-downline').text('');
    $('#warning-find-email-behalf').text('');

    var registration_type = $("input[name='team_challenge_submission[registration_type]']:checked").val();

    if (registration_type == 'register_myself' || registration_type == 'team_leader') {
      $('#form-register-myself').removeClass('hide');
      $('#form-register-behalf-other').addClass('hide');

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('.input-register-behalf').attr('disabled', true);
      $('#behalf-abo-number').removeAttr('required');
      $('.required-behalf-input').addClass('hide');
      $('#set-registration_by').val(0);

      if (registration_type == 'register_myself') {
        $('#default-leader-team').attr('disabled', true);
        $('#team-leader-input').removeClass('hide');
        $('#select-team-leader').removeAttr('disabled', true);

      } else {
        $('#default-leader-team').removeAttr('disabled', true);
        $('#team-leader-input').addClass('hide');
        $('#select-team-leader').attr('disabled', true);
      }

      if (registration_type == 'register_myself') {
        openParticipantChallengeCategory();
      } else {
        openLeaderChallengeCategory();
      }

      if ($('#form-challenge-submission').length > 0) {
        $('#behalf-participant-name').attr('disabled', true);
      }

      var with_downlines = false;

    } else {
      var register_by_abo = $("input[name=register_by_abo]:checked").val();

       if (register_by_abo == 'true') {
        disableRegisterBehalfEmail();
        $('.required-behalf-input').addClass('hide');
        $('#behalf-abo-number').attr('required', true);
        $('#behalf-abo-number').removeAttr('disabled');
        $('#required-behaf-abo-icon').removeClass('hide');
      } else {
        $('.required-behalf-input').removeClass('hide');
        $('#behalf-abo-number').attr('disabled', true);
        $('#required-behaf-abo-icon').addClass('hide');
      }

      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('#form-register-myself').addClass('hide');
      $('#form-register-behalf-other').removeClass('hide');
      $('#set-registration_by').val(0);

      $('#default-leader-team').attr('disabled', true);
      $('#team-leader-input').removeClass('hide');
      $('#select-team-leader').removeAttr('disabled', true);

      if ($('#form-challenge-submission').length > 0) {
        $('#behalf-participant-name').removeAttr('disabled');
      }

      var with_downlines = true;
      openParticipantChallengeCategory();
    }

    reloadLeaderOptions(with_downlines);
  })

  $("input[name=register_by_abo]").change(function() {
    openSubmitBtn();
    showHideAboNumber();
    $('#error-find-email-downline').text('');
    $('#error-find-downline').text('');
    $('#warning-find-email-behalf').text('');
  })

  function reloadLeaderOptions(with_downlines = false){
    var current_account_id = $('#current-account-id').val();
    var exclude_team_id    = $('#exclude-team-id').val();
    var selected_id = $('#select-team-leader').val();
    if (selected_id == undefined) {
      selected_id = null;
    }
    if (exclude_team_id == undefined) {
      exclude_team_id = null;
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'account_id': current_account_id, 'with_downlines': with_downlines, 'selected_id': selected_id, 'exclude_team_id' : exclude_team_id },
      url: "/api/services/reload_leader_options",
      success: function(response){
        $('#select-team-leader').val('');
        $('#select-team-leader').empty();
        // $('#achievement-tier-detail').empty();

        var leader_options = response.leader_options;
        var selected_id    = response.selected_id;
        $('#select-team-leader').append("<option value>Select Team Leader</option>");

        $.each(leader_options, function(_key, data) {
          let option = $("<option></option>");
              option.attr("value", data[1]);
              option.text(`${data[0]}`);

          if (parseInt(selected_id) == parseInt(data[1])) {
            option.attr('selected', true);
          }

          $('#select-team-leader').append(option);
        })
      }
    });
  }

  function showHideAboNumber() {
    var register_by_abo = $("input[name=register_by_abo]:checked").val();
    clearBehalfPersonalData();
    removeDisableRegisterBehalfEmail();

    if (register_by_abo == 'true') {
      $('#set-registration_by').val(0);
      clearBehalfEmailData();
      $('#required-behaf-abo-icon').removeClass('hide');

      $('#input-behalf-abo_number').removeClass('hide');
      $('#behalf-abo-number').attr('required', true);

      $('.input-register-behalf').attr('disabled', true);
      $('#behalf-abo-number').removeAttr('disabled');
      $('#behalf-abo-number').attr('disabled', false);
      $('.required-behalf-input').addClass('hide');

      searchDownline();
    } else {
      $('#set-registration_by').val(1);
      $('.input-register-behalf').val('');
      $('.behalf-participant-name').val('');
      $('#required-behaf-abo-icon').addClass('hide');

      // $('#input-behalf-abo_number').addClass('hide');
      $('#behalf-abo-number').val('');
      $('#behalf-abo-number').removeAttr('required');

      // $('.input-register-behalf').removeAttr('disabled');
      $('#behalf-email').removeAttr('disabled');
      $('#behalf-abo-number').attr('disabled', true);
      $('.required-behalf-input').removeClass('hide');

      searchDownlineByEmail();
    }
  }

  function searchDownline() {
    $('#behalf-abo-number').keyup(function() {
      setTimeout(() => {
        apiSearchByAbo($(this));
      }, 1000);
    })
  }

  function apiSearchByAbo(_this){
    openSubmitBtn();
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'abo_number': $(_this).val(), 'with_registered_as_guest': true },
      url: "/api/services/find_downline",
      success: function(response){
        var downline_data = response.downline_data;

        if (jQuery.isEmptyObject(downline_data)) {
          $('#error-find-downline').text('Downline not found!');
          clearBehalfEmailData();
          clearBehalfPersonalData();
        } else {
          var account = downline_data.downline_account;
          var user    = downline_data.downline_user;
          var address = downline_data.downline_address;

          $('#error-find-downline').text('');
          $('#warning-find-email-behalf').text('');
          $('#behalf-email').val(user.email);
          $('#behalf-participant-name').val(account.recognition_name);
          $('#behalf-phone-number-code').val(address.mobile_phone_1_code);
          $('#behalf-phone-number').val(address.mobile_phone_1);
        }
      }
    });
  }

  function searchDownlineByEmail() {
    var register_by_abo = $("input[name=register_by_abo]:checked").val();
    $('#error-find-downline').text('');
    $('#warning-find-email-behalf').text('');

    if (register_by_abo == 'false') {
      $('#behalf-email').keyup(function() {
        setTimeout(() => {
          apiSearchByEmail($(this));
        }, 1000);
      })
    }
  }

  function apiSearchByEmail(_this) {
    $('#error-find-email-downline').text('');
    openSubmitBtn();
    // $('#behalf-participant-name').removeAttr('disabled');
    // $('#behalf-phone-number-code').removeAttr('disabled');
    // $('#behalf-phone-number').removeAttr('disabled');

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'email': $(_this).val(), 'with_registered_as_guest': true },
      url: "/api/services/find_downline_by_email",
      success: function(response){
        var downline_data = response.downline_data;

        if (jQuery.isEmptyObject(downline_data)) {
          var other_account_data = response.other_account_data;

          $('#behalf-abo-number').val('');
          removeDisableRegisterBehalfEmail();
          clearBehalfPersonalData();

          if (!(jQuery.isEmptyObject(other_account_data))) {
            $('#error-find-email-downline').text("This email doesn't belong to your downline");

            if (!$('#form-challenge-submission').length > 0) {
              $('#behalf-participant-name').attr('disabled', true);
            }
            $('#behalf-phone-number-code').attr('disabled', true);
            $('#behalf-phone-number').attr('disabled', true);
          } else {
            // warningEmailAbo();
          }
        } else {
          var account = downline_data.downline_account;
          var user    = downline_data.downline_user;
          var address = downline_data.downline_address;

          // $('#error-find-email-downline').text('');
          $('#behalf-abo-number').val(account.abo_number);
          $('#behalf-participant-name').val(account.recognition_name);
          $('#behalf-phone-number-code').val(address.mobile_phone_1_code);
          $('#behalf-phone-number').val(address.mobile_phone_1);

          disableRegisterBehalfEmail();
        }
      }
    });
  }

  function clearBehalfPersonalData(){
    $('#behalf-participant-name').val('');
    $('#behalf-phone-number-code').val('');
    $('#behalf-phone-number').val('');
  }

  function clearBehalfEmailData(){
    $('#behalf-email').val('');
  }

  function disableRegisterBehalfEmail(){
    if (!$('#form-challenge-submission').length > 0) {
      $('#behalf-participant-name').attr('disabled', true);
    }
    $('#behalf-phone-number-code').attr('disabled', true);
    $('#behalf-phone-number').attr('disabled', true);

  }

  function removeDisableRegisterBehalfEmail(){
    // $('#behalf-participant-name').removeAttr('disabled');
    // $('#behalf-phone-number-code').removeAttr('disabled');
    // $('#behalf-phone-number').removeAttr('disabled');
  }

  function warningEmailAbo(){
    // NOTE: no longer used, but please don't delete it because clients are still not sure of the logic
    // $('#warning-find-email-behalf').text('Warning! Please update ABO/APC Number in this guest account to join the lucky draw');
    $('.btn-submit-lucky-draw').attr('disabled', true);
  }

  function openSubmitBtn(){
    $('.btn-submit-lucky-draw').removeAttr('disabled');
  }

  function changeTeamLeader(){
    $('#select-team-leader').change(function(){
      $.ajax({
        type: "GET",
        dataType:"json",
        data: { 'team_id': $(this).val() },
        url: "/api/services/get_leader_team",
        success: function(response){
          var downline_data = response.team_details;

          if (jQuery.isEmptyObject(downline_data)) {
            $('#select-challenge_category').val('');
            $('#default-challenge-category').val('');
            $('#default-challenge-category-name').val('');
          } else {
            $('#select-challenge_category').val(downline_data.challenge_category);
            $('#default-challenge-category-name').val(downline_data.challenge_category_name);
            $('#default-challenge-category').val(downline_data.challenge_category);
          }
        }
      });
    });
  }

  function openLeaderChallengeCategory(){
    $('#default-challenge-category').attr('disabled', true);
    $('#default-challenge-category').addClass('hide');

    $('#select-challenge_category').removeAttr('disabled');
    $('#select-challenge_category').removeClass('hide');

    $('#default-challenge-category-name').addClass('hide');
    $('#challenge-category-leader-desc').removeClass('hide');
  }

  function openParticipantChallengeCategory(){
    $('#default-challenge-category').removeAttr('disabled');
    $('#default-challenge-category').removeClass('hide');

    $('#select-challenge_category').attr('disabled', true);
    $('#select-challenge_category').addClass('hide');

    $('#default-challenge-category-name').removeClass('hide');
    $('#challenge-category-leader-desc').addClass('hide');
  }

  function reloadChallengeSubmission(checked_elm){
    if (checked_elm == 'register_by_other') {
      var register_by_abo = $("input[name=register_by_abo]:checked").val();

      if (register_by_abo == 'false') {
        if ($('#behalf-abo-number').val().length > 0) {
          setTimeout(() => {
            apiSearchByEmail($('#behalf-email'));
          }, 1000);
        } else {
          if ($('#behalf-email').val().length > 0) {
            // setTimeout(() => {
            //   warningEmailAbo();
            // }, 10)
          }
        }
        searchDownlineByEmail();
      } else {
        setTimeout(() => {
          apiSearchByAbo($('#behalf-abo-number'));
        }, 1000);
        searchDownline();
      }

      var with_downlines = true;
    } else {
      if ($('#form-challenge-submission').length > 0) {
        $('#behalf-participant-name').attr('disabled', true);
      }
      var with_downlines = false;
    }

    reloadLeaderOptions(with_downlines);
  }
})