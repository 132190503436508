$(document).ready(function(){

  var accordionContent = $(this).find('.accordionContent').hide();
  var accordionUplineContent = $(this).find('.accordionUplineContent').hide();

  $('body').on('click', '.platinum-downline-tickets', function(){
    var $this      = $(this);
    var level      = parseInt($this.attr('data-parent'));
    var account_id = $this.attr('data-account-id');
    var hasChild   = $this.attr('data-haschild');
    var wrapper_child = $(`#wrapper-child-${account_id}`);
    // var $target    = $this.find('.accordionContent[data-child="'+level+'"]');
    var parent_li  = $this.closest(`.wrap-parent li.li-${account_id}`);
    var $target    = parent_li.find('.accordionContent[data-child="'+level+'"]');
    var event_attendance_id = $this.attr('data-event-attendance');
    var recognition_name_params = $('.dashboard.row').attr('recognition-name');
    var quantity = $('.dashboard.row').attr('quantity');
    var quantity_own_ticket = $('.dashboard.row').attr('quantity-own-ticket');
    var balance_groups = $(this).attr("data-balance-groups") || false;

    if (wrapper_child.length > 0) {
      wrapper_child.removeClass('hide');
      // wrapper_child.toggleClass('blink');
    }

    if (!hasChild) {
      $.ajax({
        type: "GET",
        dataType:"json",
        data: { "data_parent" : level, "event_attendance_id" : event_attendance_id,
                "account_id" : account_id, "recognition_name" : recognition_name_params,
                "quantity" : quantity, "quantity_own_ticket" : quantity_own_ticket, "balance_groups" : balance_groups },
        url: `/user/event_attendances/get_children`,
        success: function(response){
          var data = "";
          var platinum_position = response.platinum_position;
          $.each( response.children, function( k, child ) {
            var child_id          = child.id;
            var recognition_name  = child.recognition_name;
            var abo_number        = child.abo_number;
            var pin               = child.pin;
            var pin_position      = child.pin_position;
            var total_active_user = child.total_active_user;
            var own_ticket        = child.own_ticket;
            var total_ticket      = child.total_ticket;
            var has_children      = child.has_children;
            var color             = '';
            var is_balance        = 'false';

            if (has_children) {
              var show_caret = "zmdi zmdi-caret-right";
              var wrapper_child_c = `<ul class="list-unstyled wrap-parent ml-3 hide" id="wrapper-child-${child_id}">` +
                                      `<li data-balance-groups=${balance_groups}>` +
                                        `<div class="d-flex organization-list"></div>` +
                                      `</li>` +
                                    `</ul>`;
            } else {
              var show_caret = "";
              var wrapper_child_c = "";
            }

            if (recognition_name_params != '') {
              var show_caret = "";
              var wrapper_child_c = "";
              var add_class = 'params_search';
            } else {
              var add_class = '';
            }

            if (balance_groups == 'true') {
              if (level+1 == 2) {
                if (pin_position < platinum_position) {
                  var is_balance = 'true';
                }
              }

              if (is_balance == 'true') {
                var color = 'blue';
              }
            }

            var children_table = `<ul class="list-unstyled wrap-parent ml-3 accordionContent" data-child="${level}">` +
                                    `<li class="li-${child_id} ${add_class}">` +
                                      `<div class="text-decoration-none organization-list-wrap align-items-center">` +
                                        `<div class="d-flex justify-content-between organization-list">` +
                                          `<div class="d-flex w-100">` +
                                            `<div class="organization-icon remove_icon platinum-downline-tickets" data-parent="${level+1}" data-account-id="${child_id}" id="account-${child_id}" data-event-attendance="${event_attendance_id}" data-balance-groups=${balance_groups}>` +
                                              `<i class="${show_caret}"></i>` +
                                            `</div>` +
                                            `<div class="ml-3 w-100">` +
                                              `<span class="d-block">` +
                                                `${recognition_name}<b>(${abo_number})</b>` +
                                              `</span>` +
                                              `<div class="row">` +
                                                `<div class="col-md-3">` +
                                                  `<span style="color: ${color}">Pin : ${pin}</span>` +
                                                `</div>` +
                                                `<div class="col-md-3">` +
                                                  `<span style="color: ${color}">Own Ticket : ${own_ticket}</span>` +
                                                `</div>` +
                                                `<div class="col-md-3">` +
                                                  `<span style="color: ${color}">Total Tickets : ${total_ticket}</span>` +
                                                `</div>` +
                                              `</div>` +
                                            `</div>` +
                                          `</div>` +
                                        `</div>` +
                                      `</div>` +
                                      wrapper_child_c +
                                    `</li>` +
                                 `</ul>`;

            data = data + children_table;
          });
          // wrapper_child.removeClass('blink');
          wrapper_child.html(data);
          $this.addClass('active-icon');
          $this.attr('data-haschild', true);

          if (data.length > 0) {
            $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down');
          } else {
            $this.find('.zmdi').first().removeClass('zmdi-caret-right');
          }

          if (recognition_name_params != '') {
            $('.params_search').attr('data-haschild', true);
          }
        }
      })
    }

    if($target.is(':hidden')){
      $this.removeClass('active-icon');
      $this.addClass('active-icon');
      $target.removeClass('active').slideUp();
      $target.addClass('active').slideDown();
      $this.find('.zmdi').first().removeClass('zmdi-caret-right').addClass('zmdi-caret-down');
    } else {
      var elm_obj = $this.find('.zmdi').first();
      if (elm_obj.hasClass('zmdi-caret-down')) {
        elm_obj.removeClass('zmdi-caret-down').addClass('zmdi-caret-right');
      }
      $this.removeClass('active-icon');
      $target.removeClass('active').slideUp();
    }

    return false;
  });

});