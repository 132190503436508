$(document).ready(function() {
  var timeout = null;

  CartJs(timeout);

  const callCartJs = () => CartJs(timeout);
  window.callCartJs = callCartJs;

  // PAYMENT AT COUNTDOWN
  // https://www.w3schools.com/howto/howto_js_countdown.asp
  if ($("#pay-due-at-countdown").length > 0) {
    var payDueAt      = $("#pay-due-at-countdown").attr("data-ended-at");
    var countDownDate = new Date(payDueAt).getTime();
    // var countDownDate = new Date("Feb 9, 2024 05:32:00").getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="pay-due-at-countdown"
      document.getElementById("pay-due-at-countdown").innerHTML = hours + ":"
      + minutes + ":" + seconds;

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        $("#complete-payment").remove();
        $("#pay-due-at-countdown").css("color", "red");
        $("#pay-due-at-countdown").text("Payment due for this order has expired!");
      }
    }, 1000);
  }
})

function CartJs(timeout) {
  $('#add-cart-item').submit(function() {
    var sumQty = 0;
    $.each($(this).find('.item-quantity'), function(id, elm){
      sumQty += $(elm).val()
    })

    if (sumQty > 0) { return true }

    return false
  })

  $("input[name='self_collection']").change(function() {
    if ($(this).prop('checked')) {
      $(".order-address-list input[type=radio]:checked").prop('checked', false)
    }
  })

  $(".order-address-list input[type=radio]").change(function() {
    if ($(this).prop('checked')) {
      $("input[name='self_collection']").prop('checked', false)
    }
  })

  $('#ticket-add-to-cart').on('click', function() {
    $('#form-ticket-add-to-cart').submit()
  })

  $("#form-place-order").submit(function () {
    $("#btn-place-order").attr("disabled", true);
    return true;
  });

  $('#online_event').change(function() {
    if ($(this).attr('data-event') == 'true') {
      $('#address-detail').addClass('d-none')
      $('#order-summary').removeClass('col-md-4').addClass('col-md-8')
    } else {
      $('#address-detail').removeClass('d-none')
      $('#order-summary').removeClass('col-md-8').addClass('col-md-4')
    }
  }).change()

  $(".select-items").change(function(){
    if ($(".select-items:checked").length > 0) {
      $("#submit-checkout-btn").attr("disabled", false);
    } else {
      $("#submit-checkout-btn").attr("disabled", true);
    }
    setTimeout(() => {
      reCalculateSelectedItemTotal();
    }, 200);
  })

  $("#select-all-items").change(function(){
    if ($("#select-all-items").is(":checked")) {
      $(".select-items").prop('checked', true);
    } else {
      $(".select-items").prop('checked', false);
      $("#submit-checkout-btn").attr("disabled", true);
    }
  })

  $(".btn-delete-item").click(function(){
    setTimeout(() => {
      if (confirm(`Are you sure?`)) {
        var checkedVals = $('.select-items:checkbox:checked').map(function() {
          return this.value;
        }).get();

        var id  = $(this).attr("data-id");
        var idx = $(this).attr("data-idx");

        $.ajax({
          type: "DELETE",
          dataType: "json",
          data: { 'selected_cart_item_ids' : checkedVals, 'idx' : idx },
          url: `/user/cart_items/${id}`,
          error: function (xhr, ajaxOptions, thrownError) {
            // don't show alert
            // alert("Something went wrong!");
          },
          success: function(response){
            if (response.errors == '') {
              var message_alert = "Item deleted successfully!";

              var idx = response.idx;
              $(`.section-item-idx-${idx}`).remove();
            } else {
              var message_alert = response.errors;
            }

            // callCartJs(); #don't call this method bc will get double alert
            callUpdateTotalCart();
            callUpdateItemQty();
            reCalculateSelectedItemTotal();

            setTimeout(() => {
              if (message_alert != undefined) {
                alert(message_alert);
              }
            }, 200);
          }
        });
        return true
      } else {
        return false
      }
    }, 100);
  });
}

function reCalculateSelectedItemTotal() {
  var total = 0;
  $('.select-items:checkbox:checked').each(function() {
    var idx   = $(this).attr("data-idx");

    if (idx != undefined) {
      var price = parseInt($(`#price-cart-item-${idx}`).val());
      var qty   = parseInt($(`#input-qty-${idx}`).val());
      var item_total = price * qty;

      total += item_total;
    }
  });

  $("#set-sub-total").text(`RM ${total}.00`);
}
