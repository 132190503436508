$(document).ready(function(){
  $('#edit-guest-abo').keyup(function(){
    setTimeout(() => {
      searchGuestRefferalUpline();
    }, 1000);
  })

  function searchGuestRefferalUpline() {
    var abo_number = $('#edit-guest-abo').val();
    var guest_account_id = $('#guest-account-id').val();

    $.ajax({
      type: "GET",
      dataType:"json",
      data: {'abo_number' : abo_number, 'guest_account_id': guest_account_id},
      url: "/api/services/get_guest_refferal_upline",
      error: function (xhr, ajaxOptions, thrownError) {
        $("#edit-guest-abo-error").append("Active System Upline not found.");
      },
      success: function(response){
        $("#guest-platinum-abo-number").val(response.platinum_abo_number);
        $("#guest-platinum-upline-name").val(response.platinum_recognition_name);

        if (response.upline_id == null) {
          $("#edit-guest-abo-error").text(response.upline_recognition_name);
          $("#active-system-upline-name").val('');
          $("#edit-refferal-asu-id").val('');
        } else {
          $("#edit-refferal-asu-id").val(response.upline_id);
          $("#edit-guest-abo-error").text('');
          $("#active-system-upline-name").val(response.upline_recognition_name);
        }
      }
    });
  }
});