const months = [ "January", "February", "March", "April",
                 "May", "June", "July", "August", "September",
                 "October", "November", "December"
               ];
var timeout  = null;


$(document).ready(function() {
  const callAppendDatePicker = (current_month, start_period, from_js) => appendDatePicker(current_month, start_period, from_js);
  window.callAppendDatePicker = callAppendDatePicker;

  var current_month = new Date()
  var next_month    = new Date(current_month.getFullYear(), current_month.getMonth()+3, 1)
  var from_js       = false

  $('#jquery-datepicker').datepicker('destroy')
  var start_period = $('#start-period-event').data('start-period')

  callAppendDatePicker(current_month, next_month, from_js);
})

function appendDatePicker(default_date, date_today, from_js) {
  if (from_js) {
    var opened_event = $('#json-event-opened-closed').data('opened');
    var closed_event = $('#json-event-opened-closed').data('closed');
  } else {
    var opened_event = $('#event-opened-closed').data('opened')
    var closed_event = $('#event-opened-closed').data('closed')
  }

  $("#jquery-datepicker").datepicker({
    numberOfMonths: 4,
    stepMonths: 4,
    defaultDate: default_date,
    onUpdateDatepicker: function(inst) {
      changeIcon();
      deleteHover();
      addToggleDateToday();
    },
    onChangeMonthYear: function(year, month, inst) {
      clearTimeout(timeout);

      var start_month = new Date(inst.selectedYear, inst.selectedMonth)
      var end_month   = new Date(year, month+2, 1)
      timeout = setTimeout(() => {
                  getEventByDate(start_month, end_month)
                }, 1000);
    },
    beforeShowDay: function(date) {
      date_format = `${date.getFullYear()}-${months[date.getMonth()]}-${date.getDate()}`

      if (date_format == date_today) {
        return [true, 'background-higlight-today']
      }

      if (opened_event.includes(date_format)) {
        return [false, 'background-success']
      }

      if (closed_event.includes(date_format)) {
        return [false, 'background-danger']
      }

      return [false, '']
    }
  });
}

function removeClassDisabled() {
  $.each($('td.background-success'), function() {
    $(this).removeClass('ui-datepicker-unselectable ui-state-disabled');
    $(this).find('span').removeClass('ui-state-default');
  })

  $.each($('td.background-danger'), function() {
    $(this).removeClass('ui-datepicker-unselectable ui-state-disabled');
    $(this).find('span').removeClass('ui-state-default');
  })
}

function changeIcon() {
  $('.ui-datepicker-next').html('<i class="fas fa-chevron-right"></i>')
  $('.ui-datepicker-prev').html('<i class="fas fa-chevron-left"></i>')
}

function deleteHover() {
  $('.ui-state-default.ui-state-highlight.ui-state-hover').removeClass("ui-state-hover ui-state-highlight ui-state-active")
}

function addToggleDateToday() {
  $('.background-higlight-today a').attr( {'data-toggle':"tooltip", "title":"Today"} );
}

function getEventByDate(start_date, end_date) {
  $.ajax({
    type: "GET",
    dataType:"script",
    data: { 'start_date' : start_date, 'end_date' : end_date },
    url: "/user/events/event_json"
  });
}