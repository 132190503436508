$(document).ready(function() {
  // ADMIN DASHBOARD
  // For online/offline event
  // $('input[type="radio"]').click(function(){
  //     var inputValue = $(this).attr("value");
  //     var targetBox = $("." + inputValue);
  //     $(".online_event").not(targetBox).hide();
  //     $(".online_event").not(targetBox).find('select, input').attr('required', false);

  //     $(targetBox).show();
  //     $(targetBox).find('select, input').attr('required', true);
  // });

  $('.select-option-event').attr('required', true)
  $('.input-online-event').change(function(){
    var online_event = $(this).val();
    if (online_event == "true") {
      var allowInput    = 'online';
      var disallowInput = 'offline';
    } else {
      var allowInput    = 'offline';
      var disallowInput = 'online';
    }
    $(`.${allowInput}-event`).removeClass('hide');
    $(`.${disallowInput}-event`).addClass('hide');
    $(`.${disallowInput}-event :input`).val(null);
    $(`.${disallowInput}-event select`).val('').change();
    $(`.${allowInput}-event :input`).prop('disabled', false);
    $(`.${allowInput}-event :input`).attr('required', true);
    $(`.${disallowInput}-event :input`).prop('disabled', true);
    $(`.${disallowInput}-event :input`).attr('required', false);
  });

  $('#button-form-event').click(function() {
    if ($('.nested-fields').not('.d-none').length >= 1) {
      $('#error-message-time-period').addClass('d-none')
      return true
    } else if ($('.nested-fields').filter('.d-none').length >= 1) {
      $('#error-message-time-period').removeClass('d-none')
      return false
    }
  })

  // ==========================================================================================================
	
  // USER DASHBOARD
	// Share event
	$('*[data-share]').unbind('click')
  $('*[data-share]').click(e => {
    e.preventDefault()
    e.stopPropagation()
    let target = e.currentTarget.getAttribute('data-share')
    var split_link = window.location.href.split('/user')
    let url = split_link[0] + split_link[1]
    let title = document.title
    let windowHeight = 350,
      windowWidth = 520,
      alignTop = screen.height / 2 - windowHeight / 2,
      alignLeft = screen.width / 2 - windowWidth / 2
    let descr = document
      .querySelector('meta[name=description]')
      .getAttribute('content')

    const openUrl = (url, close) => {
      const targetOpen = window.open(
        url,
        '',
        'top=' +
          alignTop +
          ',left=' +
          alignLeft +
          ',width=' +
          windowWidth +
          ',height=' +
          windowHeight
      )
    }

    if (target === 'facebook') {
      let targeturl = `https://www.facebook.com/sharer.php?s=100&p[title]=${title}&p[summary]=${descr}&p[url]=${url}`
      return openUrl(targeturl)
    } else if (target === 'twitter') {
      let targeturl = `https://twitter.com/share?url=${url}`
      return openUrl(targeturl)
    } else if (target === 'linkedin') {
      let targeturl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`
      return openUrl(targeturl)
    } else if (target === 'email') {
      targeturl = `mailto:?subject=${title}&body=${url}\n${descr}`
      return openUrl(targeturl, true)
    }
  })
});