$(document).ready(function() {
  $(document).ready(function(){
    $('.checkbox-read-book').change(function(){
      var playlist_id = $(this).attr("data-elearning-playlist-id");
      var resource_id = $(this).attr("data-resource-id");
      var checked     = $(this).is(":checked");

      console.log($(this))
      console.log(playlist_id)
      $.ajax({
        type: "POST",
        dataType:"json",
        data: { "resource_id" : resource_id, "checked" : checked },
        url: `/user/elearning_playlists/${playlist_id}/update_read_recommended_book`,
        success: function(response){
          $("#toast-title").attr("style", response.color);
          $("#toast-title").text(response.title);
          $("#toast-description").text(response.description);

          $('.toast').toast('show');
        }
      })
    })
  });
})