$(document).ready(function() {
  const callReloadPage = (timeNow) => ReloadPage(timeNow);
  window.callReloadPage = callReloadPage;

  function ReloadPage(timeNow) {
    window.addEventListener( "pageshow", function ( event ) {
      var historyTraversal = event.persisted || 
                            ( typeof window.performance != "undefined" && 
                                  window.performance.navigation.type === 2 );

      var startDate = new Date(timeNow);
      var endDate   = new Date();
      var seconds = (endDate.getTime() - startDate.getTime());
      var expired = seconds > 1000 * 60 * 3; // 3 minutes

      if ( historyTraversal && expired ) {
        // Handle page reload.
        window.location.reload();
      }
    });
  }

})