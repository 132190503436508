import Plyr from "../packs/vendor/plyr/plyr.js";

$(document).ready(function() {
  $('#checkbox-url').change(function(){
    if ($(this).is(':checked')) {
      $('#row-url').removeClass('hide');
      $('#row-url-format').removeClass('hide');
      $('#submit-resource').attr('disabled', false);
      $('#resource_file').addClass('hide');
      $('#input-url').prop('required', true);
      $('#select-url-format').prop('required', true);
    } else {
      $('#input-url').val('');
      $('#row-url').addClass('hide');
      $('#row-url-format').addClass('hide');
      $("#select-url-format").select2('destroy');
      $("#select-url-format").val(null);
      $("#select-url-format").select2();
      $('#resource_file').removeClass('hide');
      $('#input-url').removeAttr('required');
      $('#select-url-format').removeAttr('required');
    }
  }).change();

  $('#checkbox-downloadable').change(function(){
    if ($(this).is(':checked')) {
      $('#input-downloadable-date').removeClass('hide');
      $('#downloadable-date').prop('disabled', false);
    } else {
      $('#input-downloadable-date').addClass('hide');
      $('#downloadable-date').val(null);
      $('#downloadable-date').prop('disabled', true);
    }
  });

  $(".share-resource-link").click(function() {
    var id = $(this).attr('data-id');

    $.ajax({
      type: "GET",
      dataType:"json",
      url: `/user/resource/${id}/presigned_url`,
      success: function(response){
        $(`#clipboard-link-${id}`).val(response.presigned_url);

        $('.copy-section').attr('style', 'display: none;');
        var section = $(`.copy-link-section-${id}`);
        var input = $(`#clipboard-link-${id}`);

        section.toggle(200);

        setTimeout(function(){
          input.select();
          document.execCommand("copy");
          alert("Link copied!");
        }, 500);
      }
    })
  })

  $('.checked-elearning-folder').click(function() {
    var resource_id          = $(this).val();
    var resource_playlist_id = $('#resource-playlist-id').val();
    
    addElearningFolder(resource_id, resource_playlist_id);
  })

  function addElearningFolder(resource_id, resource_playlist_id) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'resource_id' : resource_id },
      url: `/admin/elearning_folders/${resource_playlist_id}/create_elearning_resources`,
      method: 'post',
      success: function(response) {
        handleResponse(response, resource_id);
      },
      error: function(xhr, thrownError) {
        handleError(xhr, thrownError, resource_id);
      }
    });
  }

  $('.checked-elearning-resources').click(function() {
    var resource_id          = $(this).val();
    var resource_playlist_id = $('#resource-playlist-id').val();
    
    addElearningResource(resource_id, resource_playlist_id);
  })

  function addElearningResource(resource_id, resource_playlist_id) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'resource_id' : resource_id },
      url: `/admin/elearning_playlists/${resource_playlist_id}/create_elearning_resources`,
      method: 'post',
      success: function(response) {
        handleResponse(response, resource_id);
      },
      error: function(xhr, thrownError) {
        handleError(xhr, thrownError, resource_id);
      }
    });
  }

  $('.checked-resource-playlists').click(function() {
    var resource_id        = $(this).val();
    var isChecked          = $(this).is(':checked');
    var resource_folder_id = $('#resource-folder-id').data('id');

    addResourcePlaylist(resource_id, resource_folder_id, isChecked);
  })

  function addResourcePlaylist(resource_id, resource_folder_id, isChecked) {
    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'checked' : isChecked, 'resource_id' : resource_id },
      url: `/admin/resource_folders/${resource_folder_id}/add_or_remove_resource`,
      method: 'put',
      success: function(response) {
        handleResponse(response, resource_id);
      },
      error: function(xhr, thrownError) {
        handleError(xhr, thrownError, resource_id);
      }
    });
  }

  function handleResponse(response, resource_id) {
    var color = response.color;
    var title = response.title;
    var description = response.description;

    $("#toast-title").attr("style", color);
    $("#toast-title").text(title);
    $("#toast-description").text(description);

    setTimeout(() => {
      if (title === "Failed!") {
        $("#resource_ids_" + resource_id).prop('checked', !$("#resource_ids_" + resource_id).prop('checked'));
      }
      $('.toast').toast('show');
    }, 150);
}

  function handleError(xhr, thrownError, resource_id) {
    $("#toast-title").attr("style", "color: red;");
    $("#toast-title").text("Error!");
    $("#toast-description").text(thrownError);

    setTimeout(() => {
      $("#resource_ids_" + resource_id).prop('checked', !$("#resource_ids_" + resource_id).prop('checked'));
      $('.toast').toast('show');
    }, 150);
  }

  function isYouTubeURL(url) {
    try {
      var parsedUrl = new URL(url);
      return parsedUrl.hostname.endsWith('youtube.com') || parsedUrl.hostname.endsWith('youtu.be');
    } catch (error) {
      return false;
    }
  }

  $("#input-url").keyup(function () {
    var url = $("#input-url").val().trim();
    var youtubeId = url;

    if(isYouTubeURL(url) === true) {
      if (url.indexOf("youtube") !== -1) {
        var urlParts = url.split("v=");
        youtubeId = urlParts[1].substring(0, 11);
        $("#input-url").val(youtubeId);
        $('.resource-img').attr('src','https://i.ytimg.com/vi/'+ youtubeId +'/default.jpg');
      } else if (url.indexOf("youtu.be") !== -1) {
        var urlParts = url.replace("//", "").split("/");
        youtubeId = urlParts[1].substring(0, 11);
        $("#input-url").val(youtubeId);
        $('.resource-img').attr('src','https://i.ytimg.com/vi/'+ youtubeId +'/default.jpg');
      }
    } else {
      $('.resource-img').attr('src','https://i.ytimg.com/vi/'+ youtubeId +'/default.jpg');
    }
  });

  const players = new Plyr('#player', {
    autoplay: true
  });
});
