$(document).ready(function() {
  // Get the zoom image modal
  var modal = $('#image-modal')[0];
  var video_modal = $('#video-modal')[0];
  var span = $('.close')[0]

  // $('.click-video-modal').click(function() {
  //   var video = $(this).find('source')[0];
  //   video_modal.style.display = "block";

  //   var video_play = $('<video />', {
  //   id: 'video',
  //   src: video.src,
  //   type: 'video/mp4',
  //   controls: true,
  //   class: 'text-center'
  //   });
  //   video_play.appendTo($('.append-video-modal'));

  //   clickHideModalVideo(video_modal)
  // })

  $('.click-img-modal').click(function() {
    modal.style.display = "block";
    var img_id = $(this).attr('data-img-id');

    $(`#picture-list-${img_id}`).addClass('active');
    $(`#picture-${img_id}`).addClass('active');

    clickHideModalPicture(modal)
  })

  $(document).keydown(function(event) {
    if (event.keyCode == 27) {
      span.click(modalHide(span, modal))
      span.click(modalHideVideo(span, video_modal))
    }
  });

  $('img').click(function() {
    var ticket_id = $(this).data('ticket-id')
    var attendee_info_option_id = $(this).data('attendee-info-option')
    var radio_id = `${ticket_id}-${attendee_info_option_id}`

    $(`#${radio_id}`).prop('checked', true)
  })

  // function clickHideModalVideo(modal) {
  //   $('.close').click(function() {
  //     var span = $(this)[0]
  //     span.click(modalHideVideo(span, modal))
  //   })
  // }

  function clickHideModalPicture(modal) {
    $('.close').click(function() {
      var span = $(this)[0];
      span.click(modalHide(span, modal));

      $(`.picture-list`).removeClass('active');
      $(`.carousel-item`).removeClass('active');
    })
  }

  function modalHide(span, modal) {
    span.onclick = function() {
      modal.style.display = "none";
    }
  }

  function modalHideVideo(span, modal) {
    span.onclick = function() {
      modal.style.display = 'none';
      $('.append-video-modal').find('video').remove()
    }
  }
});