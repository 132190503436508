$(document).ready(function() {
  $('#input-form').submit(function() {
    var input_file = $('.upload-data').val()
    input_file = input_file ? input_file : ''

    var file_json = 0
    if (input_file != '') {
      file_json = jQuery.parseJSON(input_file).length
    }

    var photo_video = $('.image_video_preview').length

    var valid = true;
    var total = file_json + photo_video
    if (total > 8) {
          valid = false;
          alert('Video & Photo Max 8 file')
    }
    return valid;
  });

  //slick
  $('.success-profile_img-list').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // validate input youtube link
  $('#youtube_link').on('keyup', function(){
    setTimeout(() => {
      var value = $(this).val();

      if (!!value) {
        $.ajax({
          type: "GET",
          dataType:"json",
          data: {'youtube_link' : value},
          url: "/admin/user_success_profiles/validate_youtube_link",
          success: function(response){
            var valid = response.result.valid;

            if (valid) {
              removeDisablSubmit();
            } else {
              $('#submit-btn').attr('disabled', true);
              $('#error-youtube-link').removeClass('d-none');
            }
          }
        });
      } else {
        removeDisablSubmit();
      }
    }, 100)
  })

  function removeDisablSubmit(){
    $('#submit-btn').removeAttr('disabled');
    $('#error-youtube-link').addClass('d-none');
  }
});
