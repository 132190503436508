import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  XHRUpload,
  Dashboard,
  // ImageEditor,
} from 'uppy'

const csrfToken = document.querySelector('meta[name="csrf-token"]').content

function fileUploadDashboardLocal(fileInput) {
  const uploadFileDashboard = Core({
    autoProceed: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: JSON.parse(fileInput.getAttribute('data-available-mimetype'))
    },
    onBeforeFileAdded: (currentFile, files) => {
      var limit_size = $('#file-size-limit').attr("data-limit");
      console.log(limit_size)
      console.log(currentFile)
      console.log(files)
      if (limit_size != undefined) {
        console.log(currentFile.data.size)
        console.log((parseInt(limit_size)*1024*1024))
        if (currentFile.data.size > (parseInt(limit_size)*1024*1024))
        {
          console.log('Max filesize exceded')
          alert("Max filesize exceded");
          validateImageSize();
          return false
          // return 'Max filesize exceded'
        }
      }
    }
  })
  .on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: response.body.id, // object key without prefix
      storage: 'cache',
      metadata: {
        size: response.body.metadata['size'],
        filename: response.body.metadata['filename'],
        mime_type: response.body.metadata['mime_type'],
      }
    }
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    const uploadDashInput = document.querySelector(fileInput.getAttribute('data-hidden-field'))
    uploadDashInput.value = JSON.stringify(uploadedFileData)

    // hide old image
    document.querySelectorAll(fileInput.getAttribute('data-old-preview'))[0].classList += ' d-none'
  })
  .use(XHRUpload, { endpoint: '/images/upload', bundle: false, headers: { "X-CSRF-Token": csrfToken } })
  .use(Dashboard, {
    trigger: null,
    inline: true,
    target: fileInput.getAttribute('data-target'),
    replaceTargetContent: false,
    showProgressDetails: true,
    height: 330,
    metaFields: [
      { id: 'name', name: 'Name', placeholder: 'file name' },
      { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
    ],
    browserBackButtonClose: true
  })
}

export default fileUploadDashboardLocal
